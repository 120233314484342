.card-primary {
  display: flex;
  flex-direction: column;
  background-color: var(--spt-color-background-warm);
  color: var(--spt-color-background-contrast);
  padding: 2rem;
  /* border-radius: 50px; */
  box-shadow: inset 4px 4px 14px -7px rgb(255 255 255),
    inset -4px -4px 14px -7px rgb(0 0 0 / 50%), 3px 3px 10px -8px black;
  box-shadow: inset 0px -13px 7px -14px black,
    inset 0px 14px 7px -14px rgba(0, 0, 0, 0.5294117647);
  box-shadow: 0px -13px 7px -14px #00000000,
    inset 0px 14px 7px -14px rgb(0 0 0 / 26%);
  margin: 0;
}

.card-primary p,
.card-primary h1,
.card-primary h2,
.card-primary h3,
.card-primary a,
.card-primary span {
  /* background-color: var(--spt-color-background);
  background-image: var(--spt-color-accent-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
}

.card-primary p,
.card-primary h1,
.card-primary h2,
.card-primary h3 {
  opacity: 0.7;
}
.card-priimary a {
  color: var(--spt-color-accent);
}
