.text-zoom-controls {
  display: flex;
  margin: 0;
  font-size: 1.25rem;
  font-weight: 700;
  /* color: var(--spt-color-accent); */
  text-align: center;
  /* background: var(--spt-color-background); */

  /* box-shadow: 3px 3px 10px -3px, 1px 1px 10px -7px black, */
  /* inset -3px -3px 6px -4px black; */
  z-index: 10;
  padding: 0.25em 1em 0.25em 0.5em;
  color: inherit;
}

.text-zoom-controls > p {
  margin: 0 1em 0 0;
}

.text-zoom-controls .button-wrap {
  box-shadow: 1px 1px 3px -2px black, inset 2px 2px 3px -2px white;
  background: var(--spt-color-accent);
  color: var(--spt-color-background);
  border-radius: 50px;
}
.text-zoom-controls .button-wrap > button {
  font-size: inherit;
  border: none;
  background: transparent;
  /* box-shadow: inset 1px 1px 2px -1px white, inset -1px -1px 2px -1px black; */
  color: inherit;
  padding: 0 1em;
  margin: 0 auto;
  font: inherit;
}
.text-zoom-controls .button-wrap > button:hover {
  /* transform: scale(1.25, 1); */
  background: var(--spt-color-background);
  color: var(--spt-color-accent);
  /* box-shadow: inset 1px 1px 2px -1px white, inset -1px -1px 2px -1px black,
    1px 1px 7px -5px black; */
  z-index: 10;
}
.text-zoom-controls .button-wrap .larger-button {
  border-radius: 50px 0 0 50px;
  font-weight: 900;
  padding: 0 0.75em 0.2em 1.5em;
}
.text-zoom-controls .button-wrap .smaller-button {
  border-radius: 0 50px 50px 0;
  font-weight: 900;
  padding: 0 1.5em 0.2em 0.75em;
}
.text-zoom-controls .button-wrap .reset-button {
  border-radius: 0;
  padding: 0 1em 0.2em;
}
