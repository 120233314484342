/****************************************************************
  Color & Font Themes
****************************************************************/

/* Kodchasan font loaded via npm install @fontsource/kodchasan */
/* Covered By Your Grace font loaded via npm install @fontsource/covered-by-your-grace */
@font-face {
  font-family: "Good Times RG";
  src:
    local("Good Times RG"),
    url("./assets/fonts/good_times/good_times_rg.ttf") format("truetype");
  font-weight: bold;
}

/* Orange & Blue - Bright */
/* 
Oranges #fca075  
Blues: rgb(67, 144, 233) hsl(212 79% 97% / 1)
*/
:root {
  /* 
  Primary Accent: 
      Contrasts background.
      Strong touches, possibly limited, throughout the page. 
  */
  --spt-color-accent: #fca075;
  --spt-color-accent-light: hsl(19 96% 88% / 1); /*14 lightness up */
  --spt-color-accent-dark: hsl(19 96% 58% / 1); /*14 lightness down */
  --spt-color-accent-gradient: radial-gradient(
    circle at 10% 20%,
    rgb(253, 193, 104) 0%,
    rgb(251, 128, 128) 90%
  );
  /* 
  Secondary Accent:  
      Compliments and possibly contrasts primary accent.
      Adds variety to the page.
  */
  --spt-color-accent-2: rgb(67, 144, 233);
  --spt-color-accent-2-light: hsl(212 79% 73% / 1); /*14 lightness up */
  --spt-color-accent-2-dark: hsl(212 79% 45% / 1); /*14 lightness down */
  --spt-color-accent-2-gradient: radial-gradient(
    circle at 10% 20%,
    rgb(67, 144, 233) 0%,
    rgb(78, 226, 198) 90.1%
  );
  /* 
  Ancillary accents: spot use or with gradients.
  */
  --spt-color-accent-3: rgb(251, 128, 128);
  --spt-color-accent-4: rgb(78, 226, 198);

  /* Background */
  --spt-color-background: #ffffff;
  --spt-color-background-warm: hsl(19 96% 97% / 1);
  --spt-color-background-cool: hsl(212 79% 97% / 1);
  --spt-image-background-texture: url(./assets/media/backgrounds/brushed-alum.png);

  /* 
  Background Contrast: text and anything needing maximum readability
  */
  --spt-color-background-contrast: hsl(0 0% 50% / 1);
  --spt-color-background-contrast-light: hsl(0 0% 75% / 1);
  --spt-color-background-contrast-dark: hsl(0 0% 25% / 1);

  /*
  Font Settings
*/
  --spt--font-heading: normal 700 3.6rem "Covered By Your Grace", sans-serif;
  --spt--font-heading-2: normal 700 3rem "Covered By Your Grace", sans-serif;
  --spt-spacing-heading: 0.25em;
  --spt-spacing-subheading: 0.375em;
  --spt-spacing-body-text: 0.125em;
  --spt-font-subheading: normal 700 2rem "Covered By Your Grace", sans-serif;
  --spt-font-body-text: normal 500 1.6rem "Kodchasan", sans-serif;
  --spt-font-body-text-12: normal 500 1.2rem "Kodchasan", sans-serif;
}

:root {
  --spt-navbar-height: 65px;
}

@media screen and (max-width: 900px) {
  :root {
    --spt--font-heading: normal 700 1.8rem "Covered By Your Grace", sans-serif;
    --spt--font-heading-2: normal 700 1.6rem "Covered By Your Grace", sans-serif;
    --spt-spacing-heading: 0.125em;
    --spt-spacing-subheading: 0.162em;
    --spt-font-subheading: normal 700 1.4rem "Covered By Your Grace", sans-serif;
    --spt-font-body-text: normal 500 1.2rem "Kodchasan", sans-serif;
    --spt-font-body-text-12: normal 500 0.75rem "Kodchasan", sans-serif;
  }
}

@media screen and (max-width: 600px) {
  :root {
    --spt-navbar-height: 50px;
  }
}

/****************************************************************
  defaults 
****************************************************************/
html {
  font-size: 10px;
  overscroll-behavior: none;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

:focus {
  border: 7px solid var(--spt-color-accent);
  outline: none;
}
body {
  background-color: #000000;
  background-color: var(--spt-color-background);
  /* background-image: var(--spt-image-background-texture); */
  background-blend-mode: overlay;
  color: hsl(33 100% 96% / 1);
  color: #bb2b95;
  color: var(--spt-color-accent);
  font:
    normal 500 1.6rem "Kodchasan",
    sans-serif;
  font: var(--spt-font-body-text);
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.6rem;
  font: var(--spt-font-body-text);
  letter-spacing: var(--spt-spacing-body-text);
  overscroll-behavior: none;
}
p,
h1,
h2,
h3,
h4,
h5,
h6,
a,
span {
  /* background-color: var(--spt-color-background);
  background-image: var(--spt-color-accent-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
  background-color: var(--spt-color-background);
  color: var(--spt-color-background-contrast);
}

p {
  word-break: break-word;
}

a {
  color: var(--spt-color-accent);
}
code {
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace; */
  white-space: pre-wrap;
}

h1 {
  font: var(--spt--font-heading);
  letter-spacing: var(--spt-spacing-heading);
}

h2 {
  font: var(--spt-font-subheading);
  letter-spacing: var(--spt-spacing-subheading);
}

/* SVG */
#gradient-horizontal {
  --color-stop-1: #ff4024;
  --color-stop-2: #bb2b95;
  --color-stop-3: #bb2b95;
}

#radial-gradient {
  --color-middle: #ff4024;
  --color-fill: #bb2b95;
  --color-edge: #bb2b95;
}
/******/
.section-title {
  font: var(--spt--font-heading-2);
  letter-spacing: var(--spt-spacing-heading);
  word-wrap: break-word;
}
