.card {
  /* margin: 0.5rem 0; */
  margin: 0;
}

.header {
  display: flex;
  flex-direction: column;
  /* border: 3px solid red; */
  background: var(--spt-color-background);
  color: var(--spt-color-background-contrast);
  padding: 2rem;
  border-radius: 3px;
}
