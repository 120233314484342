.footer-container {
  background: var(--spt-color-background);
  border-radius: inherit;
  box-shadow:
    inset 0px -13px 7px -14px black,
    inset 0px 14px 7px -14px rgb(15 15 15 / 53%),
    3px 3px 7px -4px #00000094;
  padding: 3em;
  margin: 1em;
}

.footer-column a {
  letter-spacing: 0.1em;
  /* text-transform: uppercase; */
  font-weight: 700;
  text-decoration: none;
  display: inline-block;
  transform-origin: left;
  transition: all 0.2s ease;
  /* border-bottom: 1px solid; */
  /* color: var(--spt-color-background);
  background: var(--spt-color-background); */
  /* box-shadow: 3px 3px 7px -5px white inset,
    -3px -3px 7px -5px rgba(0, 0, 0, 0.5) inset;
  border-radius: 0 10px 10px 0;
  padding: 0.5em 1em; */
  letter-spacing: 0.25em;
}

.footer-container p {
  font-size: 1.4rem;
  letter-spacing: 0.25em;
}

.category-headings {
  /* background: var(--spt-color-background); */
  /* box-shadow: 3px 3px 7px -5px white inset,
    -3px -3px 7px -5px rgba(0, 0, 0, 0.5) inset;
  border-radius: 10px 0 0 10px; */
  padding: 0.5em 1em;
}

// Eliminates hover effects on mobile
@media (hover: hover) {
  .footer-column a:hover {
    transform: scale(1, 1.5);
  }
}

@media screen and (max-width: 900px) {
  .footer-column > * {
    flex-direction: column;
    display: flex;
  }

  .footer-column span,
  .footer-column a {
    border-radius: 50px;
  }
}
