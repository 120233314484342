.outerwrap {
  max-width: 100%;
  > .section-anchor {
    position: absolute;
    top: calc(0px - var(--spt-navbar-height));
    display: block;
  }
}

.outerwrap h2 {
  margin-bottom: 0;
}

.outerwrap h3 {
  margin: 0 auto;
  padding: 1em;
  font: var(--spt-image-background-texture);
  box-shadow:
    inset 0 3px 10px -7px var(--spt-color-accent),
    0px -3px 10px -7px #fca0757d;
}

.outerwrap {
  > div {
    padding-bottom: 1em;
    box-shadow:
      inset 0 3px 10px -7px var(--spt-color-accent-light),
      0px -3px 10px -7px var(--spt-color-accent);
    border-radius: 3px;
    // overflow: hidden;
  }

  p {
    padding: 1em;
    font: var(--spt-font-body-text-12);
    margin: 0 auto;
  }
}

.loader-wrap {
  max-height: 200px;
  position: relative;
  min-height: 0;
  display: flex;
  width: auto;
  overflow: visible;
  padding: 12px;
  border-radius: 50px;
  margin: 0px auto;
  z-index: 10;
  transition: 0.2s all ease;
}

.outerwrap button {
  border-radius: 50px;
}

.outerwrap button:hover {
  background: var(--spt-color-accent);
  color: var(--spt-color-background) !important;
  transform: none;
}

body .loader-wrap > * {
  max-height: 200px;
  min-height: 125px;
  position: relative;
  padding: 20px 0;
  margin: 50px auto;
  overflow: visible;
  width: 100%;
  min-width: 400px;
  border-radius: inherit;
}

.json-upload-wrap > div {
  padding: 1em 1em 0;
  background: var(--spt-color-accent-light);
}
.edited-list {
  position: relative;
}
.upload-json-input {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding-bottom: 1em;
  margin-bottom: 2em;
  color: inherit;
}

.upload-json-input:focus {
  border: none;
}

.upload-json-input::-webkit-file-upload-button {
  visibility: hidden;
}
.upload-json-input.show-input-data-false {
  color: transparent;
  margin: auto;
  padding: 0;
}
.upload-json-input.show-input-data-true {
  color: inherit;
}
.upload-json-input::before {
  content: "Upload Study Plan JSON";
  margin: 0 0.5em;
  box-shadow:
    3px 3px 7px -5px white inset,
    -3px -3px 7px -5px rgba(0, 0, 0, 0.5) inset;
  border: none;
  background: inherit;
  color: inherit;
  border-radius: 50px;
  padding: 0.5em 1em;
  margin-top: 1em;
  cursor: pointer;
  transition: 0.3s all ease;
  font: var(--spt-font-body-text);
  /* font-size: calc(0.5em + 0.2vw); */
  font-feature-settings: "smcp", "c2sc";
  font-variant: all-small-caps;
  letter-spacing: 0.125em;
  font-weight: 700;
  background: var(--spt-color-background);
  color: var(--spt-color-background-contrast-light);
  text-align: center;
}
.upload-json-input:hover::before {
  background: var(--spt-color-accent);
  color: var(--spt-color-background) !important;
  transform: none;
}
.upload-json-input:active::before {
  background: var(--spt-color-accent-dark);
}

.uploaded-json-container button:hover {
  background: var(--spt-color-accent-dark) !important;
  color: var(--spt-color-background) !important;
  box-shadow:
    inset 3px 3px 10px -3px white,
    inset -3px -3px 10px -3px #0000007a;
}
.uploaded-json-container .uploaded-json-message {
  background: var(--spt-color-background-warm);
  color: var(--spt-color-background-contrast);
  box-shadow:
    inset 0px -13px 7px -14px #aeaeae,
    inset 0px 14px 7px -14px rgba(0, 0, 0, 0.5294117647);
  padding: 1em;
  p,
  h4 {
    margin: 0 auto;
    background: inherit;
    color: inherit;
    max-width: 800px;
    opacity: 1;
  }
}
.uploaded-json-container .uploaded-json-wrap {
  padding: 0 1em;

  > ul {
    padding: 0 2em;
    border-radius: 50px;
  }
  > ul > div {
    min-height: 5em;

    display: grid;
    grid-gap: 0;
    grid-template-columns: repeat(6, 1fr) 4em;
    grid-template-areas:
      "title  name  name  name method  method priority  "
      "slug   slug  slug slug slug slug slug"
      "msup   msup   msup asup asup asup asup"
      "des    des   des   des des des des"
      "url    url   url   url url url url"
      "type   author  author  author platform platform platform"
      "lectureTime  labTime    start    acomp  status status status"
      "demonstratedskillsdesc   demonstratedskillsdesc demonstratedskillsdesc demonstratedskillsdesc demonstratedskillsdesc demonstratedskillsdesc demonstratedskillsdesc"
      "demonstratedskillurl demonstratedskillurl demonstratedskillurl demonstratedskillurl demonstratedskillurl demonstratedskillurl demonstratedskillurl"
      "itemnotes itemnotes itemnotes itemnotes itemnotes itemnotes itemnotes"
      "tags tags tags tags tags tags tags"
      "markforreview  markforreview markforreview markcomplete  markcomplete markcomplete markcomplete"
      "createdAt createdAt createdAt updatedAt updatedAt updatedAt updatedAt"
      "_id _id _id _id _id _id _id"
      "id id id id id id id"
      "identifier identifier identifier identifier identifier identifier identifier "
      "masterLibraryID masterLibraryID masterLibraryID masterLibraryID masterLibraryID masterLibraryID masterLibraryID"
      "buttonContainer buttonContainer buttonContainer buttonContainer buttonContainer buttonContainer buttonContainer"
      "dependencies-container dependencies-container dependencies-container dependencies-container dependencies-container dependencies-container dependencies-container";
    grid-auto-flow: row dense;
    max-width: 100%;
  }
  li span {
    background: inherit;
    color: inherit;
    padding: 0.5em;
    border-radius: 3px;
    margin: 0.5em;
    min-height: 2em;
    text-align: left;
  }

  li::before,
  li label {
    display: flex;
    opacity: 1;
    text-align: left;
    font-size: inherit;
    justify-content: left;
  }

  li,
  [class*="priority"] {
    display: flex;
    transform: none;
    height: 100%;
    max-height: none !important;

    span {
      width: 100%;
      min-height: 2.5em;
      height: 59%;
    }
  }

  [class*="priority"] span {
    text-align: center;
  }
  // [class*="name"]::before {
  //   content: "Name";
  // }
  // [class*="slug"]::before {
  //   content: "Slug";
  // }
  // [class*="type"]::before {
  //   content: "Type";
  // }
  [class*="tags"]::before {
    content: "Tags";
  }
  [class*="identifier"]::before {
    content: "Identifier";
  }
  [class*="masterLibraryID"]::before {
    content: "Master Library ID";
  }
  // [class*="method"]::before {
  //   content: "Method (course, book, etc...)";
  // }

  [class*="progressbar"],
  [class*="url-arrow"],
  [class*="createdAt"],
  [class*="_id"] {
    display: none;
  }
}
