.outerwrap {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1em;
  /* Must be the same as the Service Selector */
  height: 65px;
  height: var(--spt-navbar-height);
}

.logo-title-outerwrap {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 300px;
  width: 60%;
  z-index: 1;
  transform: translate(-12.5%, 25%);
  /* background: var(--spt-color-background); */
  border-radius: 0 0 50px 0;
  transition: 0.2s all cubic-bezier(0, 0.27, 0.61, 0.98);
  /* box-shadow: 10px 10px 15px 0 black; */
  a {
    text-decoration: none;
  }
}

.header-title-container a {
  text-decoration: none;
}
// .header-title-container:before {
//   content: "";
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   /* box-shadow: 20px 0 0 0 var(--spt-color-background); */
//   background: var(--spt-color-background);
//   transform: scale(0.9) translate(10%, -11%);
//   /* background: red; */
//   border-radius: 50px;
// }

.spt-title {
  margin: 0 auto;
  font: var(--spt--font-heading);
  letter-spacing: var(--spt-spacing-heading);
}

.spt-title.first-word {
  position: relative;
  width: 204px;
  height: 1.5em;
  left: 0;
  top: 0;
  background: radial-gradient(50% 50% at 50% 50%, #ff4024 0%, #bb2b95 100%);
  background: var(--spt-color-accent-gradient);
  background: radial-gradient(
    50% 50% at 50% 50%,
    rgb(251, 128, 128) 0%,
    var(--spt-color-accent) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-family: Covered By Your Grace;
  font-size: 96px;
  font-size: calc(3em + 3vw);
  font-weight: 400;
  line-height: 1em;
  letter-spacing: 0em;
  text-align: left;
  font-variant: none;
  text-transform: none;
  z-index: 1;
  display: inline;
}

.spt-title.second-word {
  position: relative;
  width: 195px;
  height: 1.5em;
  left: 71px;
  top: 0;
  font-style: normal;
  background: conic-gradient(
    from -5.26deg at 32.03% 26.09%,
    #201cff -12.83deg,
    #0909a0 161.47deg,
    #201cff 347.17deg,
    #0909a0 521.47deg
  );
  background: var(--spt-color-accent-2-gradient);
  background: radial-gradient(
    circle at 10% 20%,
    rgb(67, 144, 233) 12%,
    rgb(78, 226, 198) 34.1%
  );
  background: var(--spt-color-accent-2-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-family: Covered By Your Grace;
  font-size: 48px;
  font-size: calc(0.7em + 3vw);
  font-weight: 700;
  line-height: 1em;
  letter-spacing: 0.125em;
  text-align: left;
  font-feature-settings: "kern" off;
  font-variant: none;
  transform: translate(-10%, -18%);
}

.spt-subtitle {
  font: var(--spt-font-subheading);
  letter-spacing: var(--spt-spacing-subheading);
  text-transform: capitalize;
  margin: 0 auto;
  position: relative;
  width: max-content;
  max-width: 200%;
  height: 1em;
  left: 37px;
  top: 0;
  font-style: normal;
  background: radial-gradient(50% 50% at 50% 50%, #ff4024 0%, #bb2b95 100%);
  background: var(--spt-color-accent-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-family: Covered By Your Grace;
  font-size: 24px;
  font-size: calc(0.25em + 3vw);
  font-weight: 400;
  line-height: 1em;
  letter-spacing: 0em;
  text-align: left;
  text-transform: none;
  transform: scale(0.7) translate(-18%, -152%);
  transform: scale(0.5) translate(-37%, -152%);
}

.logo-wrap {
  display: flex;
  width: 0;
  height: 0;
  max-height: var(--spt-navbar-height);
  justify-content: stretch;
  align-items: center;
  margin-left: 0;
  margin-top: 0;
  overflow: hidden;
}

.logo-wrap svg {
  position: relative !important;
  height: 100% !important;
  align-items: center;
  width: 100% !important;
  top: auto !important;
  left: auto !important;
  /* max-height: 2.75em !important; */
}

.logo-wrap path,
.logo-wrap circle {
  fill: ivory !important;
  fill: var(--spt-color-accent) !important;
  fill: url(#radial-gradient) !important;
}

.title-wrap {
  margin: 0 1em;
  transform: translate(-7%, 3%);
}

.header-functions-container {
  width: calc(100% - 155px);
  max-width: 75%;
  display: flex;
  margin: 0 0 auto;
  justify-content: space-between;
}

.subscribe-cta-wrap {
  padding: 0 1em;
  margin: 0 auto;
}
/* .logo-to-header-true,
.logo-to-header-true .title-wrap {
  display: flex;
} */

.logo-to-outerwrap-header {
  box-shadow: 3px 3px 10px -7px black;
}
.logo-title-outerwrap .spt-subtitle {
  transition: 0.3s all ease;
  transform-origin: top;
  opacity: 1;
}

.logo-to-header-header .spt-subtitle,
.logo-to-header-medium .spt-subtitle {
  transition: 0.3s all ease;
  transform-origin: top;
  opacity: 0;
}

/* .logo-to-header-true h1 {
  font-size: 3.6rem !important;
  margin: 0;
  top: 0 !important;
  left: 0 !important;
  padding: 0 !important;
  transform: none !important;
} */

/* .logo-to-header-medium.logo-title-outerwrap {
  transform: scale(0.75) translate(-25%, 0%) !important;
} */

.logo-to-header-small.logo-title-outerwrap {
  transform: scale(0.5) translate(-50%, -25%);
}

.logo-to-header-header.logo-title-outerwrap {
  transform: scale(0.5) translate(-50%, -50%) !important;
  box-shadow: none;
  background: none;
}

/* .logo-to-header-true .title-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-to-header-true .spt-title.second-word {
  top: 0;
  left: 0.25em;
  font-size: 96px;
  transform: none;
  height: auto;
  line-height: 96px;
} */

.login-status-wrap {
  flex-basis: auto;
  width: 10em;
  max-width: fit-content;
  max-height: 21px;
  margin: 0 auto;
  transform: scale(1, 1);
  transition: 0.7s all ease;
  align-self: center;
  margin: auto;
  align-items: center;
  display: flex;
  max-height: 1.75em;
  overflow: hidden;
}

.scrolled-up-false {
  // flex-basis: 0;
  // width: 1000000px;
  // max-width: 0;

  // transform: scale(0);
  transform: scale(1, 0);
  transform-origin: top;
  transition: 0.7s all ease;
}

// .scrolled-up-true {
//   flex-basis: 0;
//   // width: 1000000px;
//   // max-width: 0;
//   overflow: hidden;
//   // transform: scale(0);
//   transform-origin: top;
//   transition: 0.2s all ease;
// }
.login-status-wrap {
  [buttonuse="header-login"],
  .user-info-container {
    // flex-basis: 50%;
    // padding: 0 0.75em;
    margin: 0 auto;
    width: fit-content;
    max-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--spt-color-accent-2-gradient);
    height: 100%;
    margin: 0 0.5em;
    transition: 0.7s all ease;
  }

  .user-info-container {
    max-height: 1.25em;
  }
  .user-info-container h4 {
    color: var(--spt-color-accent-2);
    color: var(--spt-color-accent-2);
    font-size: 0.75em;
  }
}

.nav-container {
  display: flex;
  flex-grow: 1;
  justify-content: space-evenly;
  max-width: 75%;
  margin: 0 0 0 auto;
  flex-wrap: wrap;
}

.modal-nav-container > div {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  min-height: 50vh;
  max-height: 100%;
  width: 100%;
  max-width: 100%;
  font-size: calc(1rem + 7vmin);
}

.nav-container a {
  transform: scale(1);
  transition: 0.3s all ease;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 6em;
  min-height: 1em;
  overflow: hidden;
}

.nav-container a:hover,
.nav-container button:hover {
  transform: scale(1.25);
  transition: 0.3s all ease;
  z-index: 10;
}

/* MOBILE MODAL */
.mobile-menu-button {
  position: fixed;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 0;
  margin: 0.5em auto;
  z-index: 1;
  width: 0;
  height: 0;
  background: inherit;
  color: inherit;
  border: none;
  /* transform: rotate(90deg) scale(1, 3); */
  font-size: calc(1em + 2vmin);
  transition: 0.1s all ease;
}

.mobile-menu-button span {
  border: 1px solid;
  width: 100%;
  height: 0;
  position: relative;
  display: flex;
}
.menu-modal {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  background-image: radial-gradient(var(--spt-color-accent), #ffffffd4);
  transition: 0.3s;
  opacity: 0;
  pointer-events: none;
  z-index: 1000;
  > div {
    border-radius: 50px;
    padding: 12vmin;
    background: var(--spt-color-background-warm);
  }
}

.menu-modal > div > .nav-container {
  width: fit-content;
  border-radius: 50px;
  padding: calc(1em - 1vw);
  flex-direction: column;
  justify-content: space-between;
  max-width: 100%;
  font-size: calc(2em + 7vmin);
  max-height: 100%;
  overflow: auto;
  flex-wrap: unset;
}

.menu-modal > div > .nav-container > * {
  max-width: 90%;
  margin: 0 auto;
}

.menu-modal > div > .nav-container > div {
  margin: 0.5em auto;
  border: 1px solid;
  box-shadow: 0 0 20px -2px;
}

.menu-modal > div > .nav-container h2 {
  color: var(--spt-color-background);
  background-color: var(--spt-color-accent);
  -webkit-background-clip: unset;
  -webkit-text-fill-color: initial;
  padding: 0.25em 1em;
  font-size: calc(1rem + 3vw);
}

.menu-modal > div > button {
  width: 100%;
  border-radius: inherit;
  padding: 1vh 1vw;
  font-weight: 700;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  border: none;
  box-shadow:
    3px 3px 7px -5px white inset,
    -3px -3px 7px -5px rgba(0, 0, 0, 0.5) inset,
    -3px -3px 7px -5px #606060 inset,
    3px 3px 7px -5px rgb(75 75 75 / 50%) inset;
  cursor: pointer;
  font-variant: small-caps;
  /* margin-left: 1em; */
  background: transparent;
  font: var(--spt--font-heading);
  font-size: calc(1rem + 3vw);
  color: inherit;
  border: none;
  transition: 0.5s all ease;
  min-height: 3em;
  background: var(--spt-color-accent-light);
}

.menu-modal > div > button:hover {
  box-shadow:
    3px 3px 7px -5px white inset,
    -3px -3px 7px -5px rgba(0, 0, 0, 0.5) inset;
  color: var(--spt-color-background);
  background: var(--spt-color-accent-gradient);
  transition: 0.5s all ease;
  transform: none;
}
/* ******* */

.login-modal-wrap {
  .login-wrap {
    max-width: 800px;
    [class*="login-question"] button {
      margin: 1em auto !important;
    }
  }
  .login-modal-close-button {
    background: var(--spt-color-accent-2-dark);
    color: var(--spt-color-background);
    font: inherit;
    font-weight: 700;
    letter-spacing: 0.75em;
  }
  [id="db-login-container-header-login"] button {
    background: var(--spt-color-accent-2);
    color: var(--spt-color-background) !important;
    min-width: min-content;
    margin: auto;
    width: 100%;
  }
}

.demo-cta-wrap,
.start-demo-button {
  padding: 0.25em 1.25em;
  font-size: 1.6rem;
  background: var(--spt-color-accent-dark);
  color: var(--spt-color-background);
  border-radius: 50px;
  overflow: hidden;
  transition: 0.3s all ease;
  text-decoration: none;
  border: none;
}

.demo-cta-wrap {
  position: absolute;
  bottom: 0;
  transform: scale(1, 0) translateY(100%);
  border-radius: 0 0 50px 50px;
  overflow: hidden;
  transition: 0.3s all ease;
  text-decoration: none;
  border: none;
}

.demo-cta-wrap:hover {
  box-shadow: 0 0 10px 0 yellow;
}
.start-demo-button:hover {
  transform: scale(1.0625);
}

.demo-cta-wrap > div {
  padding: 0;
  font-size: 1.6rem;
  background: inherit;
  color: inherit;
}

.logo-to-outerwrap-header a.demo-cta-wrap {
  transform: scale(1) translateY(100%);
}

.add-more-button-wrap {
  position: relative;
  display: flex;
  min-width: 3em;
  max-width: 10em;
  width: auto;
  margin: auto;
  font-size: 0.75em;
  flex-grow: 1;
  border-radius: 25px;
  button {
    width: 100%;
    padding: 0.125em;
    border-radius: inherit;
  }
}

/* RESPONSIVE BREAKPOINTS */
@media screen and (min-width: 901px) {
  .spt-title.first-word {
    font-size: 86px;
  }
  .spt-title.second-word {
    font-size: 48px;
  }
  .spt-subtitle {
    font-size: 48px;
  }
}
@media screen and (max-width: 1024px) {
  /* Needs to match with Bottom Bar becoming visible (in Home page) */
  .subscribe-cta-wrap,
  .login-status-wrap {
    display: none;
  }
}

@media screen and (max-width: 900px) {
  .bottom-bar {
    flex-direction: column;
  }
  .bottom-bar p {
    font-size: calc(6px + 1vw);
  }
  .header-title-container {
    transform: scale(0.8) translate(0, -3%);
  }
  .title-wrap {
    transform: translate(-23%, -4%);
  }
  .spt-subtitle {
    transform: scale(0.6) translate(-9%, -152%);
  }
  .logo-to-header-header.logo-title-outerwrap {
    transform: scale(0.5) translate(-43%, -32%) !important;
  }
  .social-connect-container {
    width: calc(1em + 8vw);
  }
}

@media only screen and (max-width: 750px) {
  .title-wrap {
    transform: translate(-22%, 0%);
  }
  .spt-title.second-word {
    transform: translate(-3%, -18%);
  }
}
@media only screen and (max-width: 650px) {
  .header-title-container {
    transform: scale(0.9) translate(0%, -5%);
  }

  .logo-to-header-header.logo-title-outerwrap {
    transform: scale(0.5) translate(-43%, -41%) !important;
  }

  .social-connect-container {
    width: calc(1em + 20vw);
  }

  .nav-container a {
    display: none;
  }
  .modal-nav-container .small-header-nav {
    display: flex;
  }
  .mobile-menu-button {
    display: flex;
    width: 3em;
    height: 1em;
  }
  .title-wrap {
    transform: translate(-25%, 6%);
  }
  .spt-subtitle {
    transform: scale(0.6) translate(-23%, -152%);
  }
  .nav-container {
    flex-direction: row-reverse;
  }

  body .nav-container .add-more-button-wrap {
    // position: fixed;
    // top: 0;
    // right: 0;
    // width: 25%;
    // height: var(--spt-navbar-height);
    // max-height: calc(var(--spt-navbar-height) - 5.5px);
    // border-radius: 0 0 0 25px;
    font-size: calc(var(--spt-navbar-height) - 2vmax);
    button {
      width: 100%;
      padding: 0;
      height: 100%;
      background: var(--spt-color-background);
      color: var(--spt-color-accent);
      box-shadow: none;
      font-size: 28px;
      span {
        margin: auto;
        display: flex;
        justify-content: flex-end;
      }
    }
  }
  .demo-cta-wrap,
  .start-demo-button {
    max-width: 36%;
    right: 3px;
    display: flex;
    position: absolute;
    font-size: 16px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 15px;
    font-variant: small-caps;
    font-weight: 700;
    padding: 0.25em 1em;
  }
  .demo-cta-wrap {
    font-size: 12px;
    letter-spacing: 0;
    height: 90%;
    text-align: center;
  }
  .logo-to-outerwrap-header a.demo-cta-wrap {
    transform: translateY(-50%);
  }
  .demo-cta-wrap > div {
    font-size: inherit;
    text-align: left;
    white-space: break-spaces;
    margin: auto;
  }
}

@media only screen and (max-width: 550px) {
  .header-title-container {
    transform: scale(0.95) translate(0%, 3%);
  }

  .logo-to-header-header.logo-title-outerwrap {
    .title-wrap {
      transform: scale(0.5) translate(-43%, -46%) !important;
      height: 0;
      width: 0;
      overflow: hidden;
    }
    .logo-wrap {
      position: relative;
      display: flex;
      width: fit-content;
      height: 100%;
      left: 8%;
      margin-top: calc(var(--spt-navbar-height) * 0.333);
      overflow: visible;
    }
  }
  .spt-subtitle {
    transform: scale(0) translate(-27%, -152%);
  }
}
@media only screen and (max-width: 280px) {
  .demo-cta-wrap,
  .start-demo-button {
    font-size: 12px;
  }
}
