.page-wrap {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  box-shadow: 40px 40px 40px -40px hsla(356, 81%, 58%, 1);
  box-shadow: 40px 40px 40px -40px var(--spt-color-accent);
  box-shadow: 3px 0px 40px 7px var(--spt-color-accent-2);
  border-radius: 0;
}

.outerwrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
}

.login-stats-container {
  max-width: 75%;
  top: var(--spt-navbar-height);
  left: 100%;
  position: relative;
  transform: translate(-100%, 0%);
  padding: 1em 3em 5em 1em;
  display: flex;
  justify-content: right;
  align-items: flex-end;
  flex-direction: column;
}

.login-outer-wrap {
  max-width: 80%;
  p {
    background: transparent;
    max-width: 75%;
  }
  div,
  p {
    display: flex;
    align-content: flex-end;
    justify-content: right;
    margin: 0;
    padding: 0;
    flex-wrap: wrap;
    // width: fit-content;
    width: 100%;
    max-width: 100%;
  }
  button {
    background: var(--spt-color-accent-2-gradient) !important;
    color: var(--spt-color-background) !important;
  }
  div,
  p,
  button {
    flex-grow: 1;
  }
  span[id*="db-login-status"] span:not([class*="not-logged-in"]) {
    padding: 0;
  }
}

.stats-outer-wrap {
  width: 100%;
  // padding: 1em;
  margin: 1em 0;
}

.angled-rectangle {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 800px;
  z-index: -1;
  height: 114%;
}

.angled-rectangle:after {
  content: "";
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  max-height: 800px;
  color: var(--spt-color-accent);
  background: linear-gradient(
    135deg,
    #000000 25%,
    transparent 35%,
    transparent 100%
  );
  background: linear-gradient(
    135deg,
    #000000 25%,
    #0f0f0f7d 0px,
    transparent 26%,
    transparent 100%
  );
  background: linear-gradient(
    135deg,
    #ffffff 25%,
    rgba(15, 15, 15, 0.4901960784) 0px,
    transparent 26%,
    transparent 30%,
    #ffffff 25%
  );
  background: linear-gradient(
    135deg,
    #fff 25%,
    hsl(128.08deg 100% 50% / 0%) 0,
    transparent 26%,
    transparent 25%,
    #fff 0,
    #fff 27%,
    hsla(0, 0%, 6%, 0.49) 27%,
    transparent 26%,
    transparent 28%,
    #fff 0,
    #fff 29%,
    hsl(128.08deg 100% 50% / 0%) 0,
    transparent 30%,
    transparent 30%,
    #fff 0
  );
  height: 114%;
}

.angled-rectangle .background {
  min-width: 100%;
  height: inherit;
}
.db-error-message {
  font: var(--spt-font-subheading);
  max-width: 800px;
  margin: 2em auto;
}

.welcome-section-container {
  position: relative;
  overflow: hidden;
  // padding: 370px 0 100px;
}

@keyframes move-it {
  0% {
    background-position: initial;
  }
  100% {
    background-position: 100px 0px;
  }
}

.background-video-wrap {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  overflow: hidden;
}

.background-video-wrap {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  overflow: hidden;
  position: absolute;
  top: 0;
  max-width: 1200px;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  margin: 0 auto;
  width: 100%;
  background: var(--spt-color-accent-2);
  // background: repeating-linear-gradient(
  //   90deg,
  //   #606dbc,
  //   #606dbc 5%,
  //   #465298 5%,
  //   #465298 10%
  // );
  // background-size: 100px 100px;
  // animation: move-it 2s linear infinite;
  svg {
    height: 100%;
    width: 100%;
  }
}

.background-video {
  object-fit: cover;
  min-height: 100%;
  width: 100%;
  max-width: calc(100% - 9px);
  transform: translateX(-9px);
}

.main-music-player {
  position: relative;
  width: 100%;
  max-width: 60%;
  margin: 3em 2.5% auto auto;
  height: max-content;
}

.main-music-player-wrap > div > div:nth-child(2) > div:first-child {
  box-shadow: none;
}

.main-music-player-wrap {
  height: 100%;
  background: var(--spt-color-background);
  padding: 1em 0 0.25em;
  border-radius: 50px;
}

.main-music-player-wrap:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  max-width: 100%;
  max-height: 100%;
  border-radius: 50px;
  box-shadow:
    inset 10px 10px 30px -20px #ffffff82,
    inset -0px -0px 20px 0px var(--spt-color-accent);
  pointer-events: none;
}

// BACKGROUND
@keyframes updown {
  0% {
    top: 0;
  }
  50% {
    top: 110%;
  }
  100% {
    top: 0;
  }
}

@keyframes leftright {
  0% {
    left: -50%;
  }
  50% {
    left: 0%;
  }
  100% {
    left: -50%;
  }
}

@keyframes vastag {
  0% {
    width: 8vh;
  }
  50% {
    width: 32vh;
  }
  100% {
    width: 8vh;
  }
}

@keyframes forog {
  100% {
    transform: rotate(360deg);
  }
}

// .background-video {
//   background: radial-gradient(#500, #000);
//   margin: 0;
//   overflow: hidden;
//   position: relative;
//   top: -4vh;
//   filter: contrast(22);
//   // the following line fixes IOS Safari buf with filter
//   transform: translate3d(0, 0, 0);
//   background: var(--spt-color-accent-2-gradient);
// }

// .background-video ::after {
//   content: " ";
//   display: block;
//   position: absolute;
//   background: linear-gradient(
//     rgba(255, 255, 255, 0.6),
//     rgba(128, 128, 255, 0.3)
//   );
//   width: 4vh;
//   height: 70vh;
//   margin-left: -17vh;
//   left: 50%;
//   bottom: 20vh;
//   z-index: 2;
//   border-radius: 1vh 1vh 2vh 2vh;
//   filter: blur(0.5vh);
//   // the following line fixes IOS Safari buf with filter
//   transform: translate3d(0, 0, 0);
//   transform: skewX(-2deg);
// }

.bubble {
  height: 9vh;
  min-width: 150vw;
  border-radius: 40%;
  background: var(--spt-color-accent-dark);
  position: absolute;
  filter: blur(4vh);
  // the following line fixes IOS Safari buf with filter
  transform: translate3d(0, 0, 0);
  animation:
    updown linear 14s infinite,
    leftright ease-in-out 10s infinite,
    vastag linear 26s infinite,
    forog linear 28s infinite;
}
.bubble:nth-child(2) {
  transform: rotate(-45deg);
  animation:
    updown ease-in 18s infinite,
    leftright ease-in-out 24s infinite,
    vastag linear 20s infinite,
    forog linear 26s infinite;
}
.bubble:nth-child(3) {
  transform: rotate(45deg);
  animation:
    updown ease-out 20s infinite,
    leftright ease-in-out 30s infinite,
    vastag linear 26s infinite,
    forog linear 28s infinite;
}
.bubble:nth-child(4) {
  transform: rotate(-70deg);
  animation:
    updown ease-in-out 24s infinite,
    leftright ease-in-out 36s infinite,
    vastag linear 28s infinite,
    forog linear 38s infinite;
}
.bubble:nth-child(5) {
  transform: rotate(77deg);
  animation:
    updown ease-in-out 16s infinite,
    leftright ease-in-out 8s infinite,
    vastag linear 34s infinite,
    forog linear 44s infinite;
}
.bubble:last-child {
  transform: rotate(180deg);
  animation:
    updown linear 50s infinite,
    leftright ease-in-out 26s infinite,
    vastag linear 16s infinite,
    forog linear 34s infinite;
}

.demo-error-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    356deg,
    transparent,
    hsl(0deg 0% 100% / 90%),
    hsl(0deg 0% 100% / 90%),
    transparent
  );
  color: #5c5b5b;
  z-index: 10000000;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1em;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.demo-error-modal > * {
  opacity: 0;
  animation: fadeIn 7s ease forwards;
  animation-delay: 3s;
}
/********************************
*** Responsive Breakpoints
********************************/
@media only screen and (min-width: 1025px) {
  .bottom-bar-wrap {
    display: none;
  }
}

@media only screen and (max-width: 900px) {
  .main-music-player {
    margin: calc(9em - 5vw) 2.5% auto auto;
  }
  .page-wrap > [data-elmtype="card"] {
    padding: 0.5vw;
  }
}

@media only screen and (max-width: 650px) {
  .login-stats-container {
    // padding: 1em 1em 1em 3em;
    max-width: 75%;
    p[id*="db-login-status-container"] {
      justify-content: flex-end;
    }
    span[id*="db-login-status"] {
      max-width: 75%;
      margin: 0;
    }
  }
  .login-outer-wrap {
    [class*="not-logged-in-text"] {
      padding: 2em;
    }
  }
}
@media only screen and (max-width: 550px) {
  .welcome-section-container {
    padding-bottom: 50px;
  }
  .login-stats-container {
    padding: 1em 1em 1em 3em;
    max-width: 93%;
    p[id*="db-login-status-container"] {
      justify-content: flex-end;
    }
    span[id*="db-login-status"] {
      max-width: 75%;
      margin: 0;
      background: transparent;
    }
  }

  .login-outer-wrap div,
  .login-outer-wrap p {
    justify-content: center;
  }
  .angled-rectangle:after {
    background: linear-gradient(
      135deg,
      #ffffff 21%,
      rgba(15, 15, 15, 0.4901960784) 0px,
      transparent 22%,
      transparent 25%,
      #ffffff 18%
    );
  }
  .main-music-player {
    margin: calc(13em - 9vw) auto auto auto;
    width: 100%;
    min-width: 100%;
  }
}
