.master-parent-group {
  position: relative;
  box-shadow:
    inset -2px -2px 4px -3px #000000,
    inset 2px 2px 4px -2px white;
  flex-grow: 1;
  padding: 0 2em;
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  flex-basis: min-content;
  border-radius: 50px 50px 50px 0;
  margin: 1em 1em 0.1em;
  overflow: hidden;

  > button span {
    /* font: var(--spt-font-body-text-12); */
    font-size: 1rem;
    display: flex;
    // visibility: hidden;
    width: 4em;
    overflow: hidden;
    height: 1em;
  }

  // > button span:before {
  //   content: "\25be More \25be";
  //   visibility: visible;
  // }

  datalist {
    display: none;
  }
}

.pseudo-collapsible-elm {
  max-height: 3em;
}
.collapsible-elm-open {
  max-height: none;
}
.master-parent-group > button:hover {
  transform: scale(1.125, 1);
}

.master-parent-group > div {
  display: flex;
  flex-wrap: wrap;
}

.master-parent-group li {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 100%;
  // padding: 0 1em;
  min-width: 0;
  box-shadow:
    1px 1px 3px -2px white inset,
    -1px -1px 3px -2px hsl(0deg 0% 0% / 33%) inset;
  border-radius: 39px;
  span {
    background: inherit;
    color: inherit;
  }
}
li[class*="featured-url-arrow-wrap"] {
  box-shadow: none;
}

.master-parent-group ::placeholder {
  color: inherit;
  opacity: 0.25;
  font-style: italic;
  font: inherit;
  font-variant: all-small-caps;
}

[data-container-type="collapsibleElm"] > .group-title {
  display: none;
  // display: flex;
  /* flex-direction: column; */
  // -webkit-background-clip: unset;
  // -webkit-text-fill-color: unset;
  // background: inherit;
  // color: inherit;
  // /* padding: 0.5em 1em; */
  // min-width: 100%;
  // border-radius: 50px 50px 0 0;
  // text-align: center;
  // margin: 0;
  // opacity: 0;
  // max-width: 7.5em;
  // min-width: 7.5em;
  // height: 3em;
}

.master-parent-group
  > [data-container-type="collapsibleElm"]
  > .group-title
  > span {
  width: 100%;
  /* -webkit-background-clip: unset; */
  -webkit-text-fill-color: unset;
  background: inherit;
  color: inherit;
}
.master-parent-group > div > .group-title .id {
  display: none;
  font-size: 0.5em;
}

.master-parent-group > div > .group-title:hover .id {
  display: flex;
}
.master-parent-group > div > .group-title .id {
  display: none;
  font-size: 0.5em;
}

.subgroup {
  position: relative;
  margin: 1em 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0;
  max-width: 100%;
}

.group > .subgroup > .group-title {
  background: var(--spt-color-accent-gradient);
  color: var(--spt-color-background);
  background-clip: unset;
  -webkit-text-fill-color: unset;
  padding: 0.125em 1em;
  /* max-width: min-content; */
  display: flex;
  margin: 1em 0 0.5em;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.subgroup > .group-title :last-child {
  text-align: right;
  border: 3x solid red;
}

.subgroup > .group-title :first-child {
  text-align: left;
}

.subgroup:not(.subgroup.tags) {
  border: 1px solid;
  padding: 1em 1em 0;
}

.subgroup:not(.subgroup.tags) > div {
  width: 100%;
}

.subgroup-set-child .group-title {
  font-family: Arial;
}

.subgroup .group-title {
  font: inherit;
  letter-spacing: inherit;
  background: inherit;
  color: inherit;
  -webkit-text-fill-color: inherit;
  font-variant: all-small-caps;
  opacity: 0.5;
  transition: 0.2s all ease;
}

.subgroup:hover .group-title {
  opacity: 1;
}

.sourceURLObj,
.defaultEmbed {
  width: 100%;
}

.sourceURLObj button {
  display: none;
}

.subgroup.tags {
  margin: 0;
  flex-grow: 1;
  padding: 0.25em 0.5em;
  padding: 0 0;
  grid-area: tags;
  width: 100%;
  padding: 0 1em;
  background: transparent;
}
.subgroup.tags li {
  padding: 0;
}

.subgroup.tags .title {
  margin: 0;
}

.subgroup.dependencies {
  display: none;
}

.dependencies-container .group-title,
.dependencies-container:hover .group-title {
  opacity: 0;
}
.subgroup label[for^="tags"],
.subgroup .tags .group-title {
  display: none;
}
.dependencies-container {
  display: none;
}

[data-section="studyPlan-goals"] .dependencies-container {
  display: initial;
}

body .master-parent-group .dependencies-container {
  grid-area: dependencies-container;
  overflow: hidden;
  max-width: 100%;
  margin: 1em 0.5%;
  background: var(--spt-color-background-contrast);
  box-shadow:
    inset 1px 1px 3px -2px black,
    inset -1px -1px 3px -2px white;
  padding: 0 1%;
  border-radius: 25px 6.25px 50px;
  /* border: 1px solid var(--spt-color-accent) !important; */
  /* border-left: 7px solid var(--spt-color-accent) !important; */
  color: var(--spt-color-background);
}

body .dependencies-container > h3 {
  background: inherit;
  color: inherit;
  font: var(--spt--font-heading-2);
  font-family: Kodchasan, arial;
  font-weight: 700;
  font-feature-settings: "smcp";
  font-variant: small-caps;
  padding: 0.5em 1em;
  margin: 0 auto;
  width: calc(100% + 1em);
  margin-left: -0.5em;
  box-shadow:
    inset 1px 1px 3px -2px black,
    0px 4px 8px -4px white;
}

body .master-parent-group .dependencies-container > ul.subgroup {
  border: 1px solid var(--spt-color-accent) !important;
  border-left: 3px solid var(--spt-color-accent) !important;
  box-shadow:
    inset 1px 1px 3px -2px black,
    inset -1px -1px 3px -2px white;
  border-radius: inherit;
  overflow: hidden;
  border: none !important;
  padding: 0;
}

body .dependencies-container [data-parentmastertype="step"] {
  background: var(--spt-color-accent-gradient);
}

.button-container {
  flex-basis: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* background: #470d2896; */
  grid-area: buttonContainer;
  max-width: none;
  width: 100%;
  margin: auto;
  padding: 2em;
  background: #00000021;
  // border-radius: 0 0 50px 0;
  box-shadow: inset -1px 8px 10px -12px black;
}

.form-button {
  position: relative;
  background: var(--spt-color-accent-light);
  color: var(--spt-color-accent-dark);
  font-weight: 700;
  border: none;
  border-radius: 0;
  padding: 0.5em 2em;
  box-shadow:
    inset 5px 5px 9px -5px var(--spt-color-accent-light),
    inset -5px -5px 9px -5px var(--spt-color-accent-dark);
  transition: 0.3s all ease;
  margin: 0 0.5em;
  cursor: pointer;
  flex-grow: 1;
  transition: 0.4s all ease;
  border-radius: 6.25px;
}

.form-button span {
  pointer-events: none;
  background: transparent;
  color: inherit;
  font-variant: small-caps;
}

.show-hidden-form-button {
  flex-shrink: 1;
  flex-grow: 0;
}

.dependencies-container .show-hidden-form-button {
  display: none;
}
.delete-form-button {
  flex-grow: 0;
  flex-basis: min-content;
  width: min-content;
  padding: 1em 4em;
  /* opacity: 0.5; */
  /* border: 1px solid var(--spt-color-accent-dark); */
}

@keyframes pulseYellow {
  0% {
    box-shadow: 0 0 0px 0px yellow;
  }
  10% {
    box-shadow: 0 0 10px 3px yellow;
  }
  100% {
    box-shadow: 0 0 0px 0px yellow;
  }
}

@keyframes pulseWhite {
  0% {
    box-shadow: 0 0 0px 0px wheat;
  }
  10% {
    box-shadow: 0 0 5px 3px wheat;
  }
  20% {
    box-shadow: 0 0 0px 0px wheat;
  }
  100% {
    box-shadow: 0 0 0px 0px wheat;
  }
}

@keyframes jumpPulseAccent {
  0% {
    box-shadow: 0 0 0px 0px var(--spt-color-accent);
  }
  10% {
    box-shadow: 0 0 10px 3px var(--spt-color-accent);
  }
  100% {
    box-shadow: 0 0 0px 0px var(--spt-color-accent);
  }
}

@keyframes pulseAccent {
  0% {
    box-shadow: 0 0 0px 0px var(--spt-color-accent);
  }
  10% {
    box-shadow: 0 0 10px 3px var(--spt-color-accent);
  }
  100% {
    box-shadow: 0 0 0px 0px var(--spt-color-accent);
  }
}

.submit-form-button {
  position: relative;
  min-width: 100%;
  color: var(--spt-color-accent);
  border: none;
  border-radius: 50px;
  padding: 1em 2em 0.5em;
  /* background: var(--spt-color-background); */
  box-shadow:
    inset 1px 1px 4px -2px white,
    inset -1px -1px 4px -2px black;
  cursor: pointer;
  width: 100%;
  font: var(--spt-font-subheading);
  font-size: 2.4rem;
  letter-spacing: var(--spt-spacing-subheading);
  border: 1px outset var(--spt-color-accent);
  animation: StudyPlanItemsList_jumpPulseAccent__ErEur 2s ease infinite;
  background: var(--spt-color-background-warm);
  margin: 1em;
}

.form-button:hover {
  position: relative;
  background: var(--spt-color-accent-light);
  color: var(--spt-color-background);
  transition: 0.2s all ease;
}

.master-parent-group {
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  input[type*="date"]::-webkit-calendar-picker-indicator {
    display: none;
  }
}

[class*="edited-list"] li {
  select {
    -webkit-appearance: auto;
    -moz-appearance: auto;
  }
  input[type*="date"]::-webkit-calendar-picker-indicator {
    display: initial;
  }
}

.edited-list .form-button:hover {
  position: relative;
  background: yellow;
  color: black;
  transition: 0.3s all ease;
}

.edited-list .form-button {
  margin: 0.5em;
}
.edit-button-target-name {
  color: inherit;
  font-style: italic;
}
.submit-form-button span {
  text-transform: capitalize;
  text-transform: capitalize;
  -webkit-background-clip: unset;
  color: inherit;
  background: transparent;
  -webkit-text-fill-color: unset;
  pointer-events: none;
}

.edited-list {
  animation: pulseYellow 14s ease infinite;
  padding: 1em !important;
  background: var(--spt-color-accent-gradient);
  color: var(--spt-color-background);
  margin: 1em auto 0;
  .dependencies-container {
    position: relative;
    pointer-events: none;
  }
  .dependencies-container:after {
    content: "Dependencies are locked when the parent is in edit mode";
    background: #ffffffdb;
    color: black;
    padding: 3em;
    display: block;
    text-align: center;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    width: 100%;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 2rem;
  }
}

ul[class*="edited-list"] > [data-container-type="collapsibleElm"] > li {
  flex-basis: 100%;
  width: 100%;
  max-width: 100%;
  max-height: none !important;
  a[class*="url-arrow"] {
    display: none;
  }
}

ul[class*="edited-list"]
  > [data-container-type="collapsibleElm"]
  > li[class*="priority"] {
  transform: none !important;

  &:before {
    font-size: inherit;
    padding: inherit;
  }
}

ul[class*="edited-list"] > [data-container-type="collapsibleElm"] > li > {
  textarea,
  [class*="sizable-textarea"],
  input,
  select {
    min-height: 2em;
    font: inherit;
    padding: 0.125em 1em;
    line-height: normal;
    border-radius: 0 0 11px 11px;
    box-shadow:
      inset 2px 2px 3px -2px black,
      inset -2px 2px 3px -2px lightgrey;
    border: none;
    width: 100%;
    min-width: 100%;
    margin: 0 auto;
    background: var(--spt-color-background);
    color: var(--spt-color-background-contrast-dark);
    font-weight: 500;
  }
}
// ul[class*="edited-list"] label {
//   opacity: 1;

// }

:not([class*="edited-list"]) > [data-container-type="collapsibleElm"] {
  > li[class*="method"] {
    max-height: 2em;
    height: 100%;
    transform: translateY(-10%);
  }
  > li[class*="method"],
  > li[class*="method"] {
    box-shadow: none;
  }
}

:not([class*="edited-list"])
  > [data-container-type="collapsibleElm"]
  > li[class*="priority"],
:not([class*="edited-list"])
  > [data-container-type="collapsibleElm"]
  > li[class*="method"] {
  box-shadow: none;
  padding: 0;
}

ul[class*="edited-list"]
  > [data-container-type="collapsibleElm"]
  > [class*="method"]:before {
  text-overflow: ellipsis;
  overflow: hidden;
  content: "method";
}

ul[class*="edited-list"]
  > [data-container-type="collapsibleElm"]
  > li[class*="progressbar"] {
  display: none;
}

ul[class*="edited-list"] li[class*="name"] {
  grid-column-start: 1;
}

ul[class*="edited-list"] {
  li:before,
  label {
    position: relative;
    text-align: center;
    margin: 0 auto;
    padding: 0.25em 0.75em;
    opacity: 1;
    border-radius: 11px 11px 0 0;
    width: 100%;
    margin: 1em auto 0.25em;
  }
}

ul[class*="edited-list"]
  > [data-container-type="collapsibleElm"]
  > li[class*="type"]
  label {
  display: initial;
}

/*** FOR LISTS ***/
.list {
  position: relative;
  display: flex;
}

.list > .subgroup .subgroup-set {
  position: relative;
  flex-direction: column;
}

.end-parent {
  position: relative;
}

.end-parent > li {
  display: flex;
  padding: 0 1em !important;
  text-align: left;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

[data-markedcomplete="true"] {
  position: relative;
}

body ul[data-markedcomplete="true"] {
  &:before {
    content: "";
    background-color: rgb(85 85 85 / 68%);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    content: "Completed";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    width: -moz-fit-content;
    width: 100%;
    min-width: min-content;
    text-align: center;
    font-size: 0.9rem;
    padding: 0;
    letter-spacing: 0.5em;
    border-radius: 25px;
    margin-left: 37px;
    font-weight: 700;
    height: 1.55em;
    background: var(--spt-color-accent-light);
    color: var(--spt-color-accent-dark);
  }
  li[id*="name-item"] {
    transform: translateY(-5%);
  }
  [class*="featured-url-arrow-wrap"] {
    transform: translateY(-1%) !important;
  }
}

[data-forreview="true"] {
  position: relative;
}

body ul[data-forreview="true"] {
  &:before {
    content: "";
    background-color: rgb(0 0 0 / 59%);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    content: "Review Needed";
    background: var(--spt-color-accent-3);
    color: var(--spt-color-background-warm);
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    width: -moz-fit-content;
    width: 100%;
    min-width: min-content;
    text-align: center;
    font-size: 0.9rem;
    padding: 0;
    letter-spacing: 0.5em;
    border-radius: 25px;
    margin-left: 37px;
    font-weight: 700;
    height: 1.55em;
  }
  li[id*="name-item"] {
    transform: translateY(-5%);
  }
  [class*="featured-url-arrow-wrap"] {
    transform: translateY(-1%) !important;
  }
}

ul ul[type="hold"]:after {
  content: "On Hold";
  background: var(--spt-color-background-contrast);
  color: var(--spt-color-background);
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -5%);
  width: -moz-fit-content;
  width: fit-content;
  min-width: 11em;
  text-align: center;
  font-size: 0.9rem;
  padding: 0 2em;
  letter-spacing: 0.5em;
  border-radius: 25px;
  font-weight: 500;
}

@media screen and (max-width: 900px) {
  .master-parent-group > {
    div > {
      li {
        min-width: 0;
      }
      li[id*="progressbar-item"] {
        min-width: fit-content;
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .master-parent-group {
    border-radius: 12.5px;
    margin: 2vh auto;
    width: 100%;

    > button {
      max-height: 3em !important;
    }
  }
  ul {
    ul[data-markedcomplete="true"]:after,
    ul[data-forreview="true"]:after,
    ul[type="hold"]:after {
      font-size: calc(0.2rem + 1vmin);
    }
  }

  div[class*="pseudo-collapsible-elm"] {
    li[class*="method"],
    li[class*="priority"] {
      display: none;
    }
  }
}

@media screen and (max-width: 550px) {
  // ul[type="step"],
  // ul[type="hold"] > div > {
  //   li[id*="name"],
  //   li[id*="progressbar"] {
  //     padding: 0.75em 0;
  //   }
  // }
}
