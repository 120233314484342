.error-container {
  padding: 0 1vh;
  p,
  h3 {
    white-space: break-spaces;
    background: transparent;
    color: inherit;
  }
}

.error-text-container {
  max-width: 800px;
}

.error-text-container {
  white-space: break-spaces;
}
