.bottom-bar {
  position: fixed;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  left: auto;
  max-width: calc(1200px + 3.3em);
  bottom: 0;
  width: 100%;
  margin: 0 auto;
  background: var(--spt-color-background);
  margin: 0 auto;
  border-radius: 50px 50px 0 0;
  box-shadow: inset 2px 2px 7px -5px rgb(255 255 255),
    inset -1px -1px 7px -5px rgb(0 0 0 / 50%), -1px -1px 15px -9px black;
  overflow: hidden;
  padding: 0 2vw;
  background: var(--spt-color-accent-2-gradient);
  color: var(--spt-color-background);
  font-variant: small-caps;
  z-index: 99;
  p {
    background: transparent;
    color: var(--spt-color-accent-2);
  }
}

.bottom-bar:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(100% - 1em);
  height: calc(100% - 1em);
  background: var(--spt-color-background-warm);
  transform: translate(-50%, -50%);
  border-radius: inherit;
  z-index: -1;
}

.bottom-bar__xGqPa > * {
  flex-basis: 33.33%;
  justify-content: center;
  align-items: center;
}

.user-info-container {
  width: fit-content;
  max-height: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: var(--spt-color-accent-2-gradient);
  height: 1.5em;
  margin: 0 0.5em;
  transition: 0.7s all ease;
}

.user-info-container span {
  background: var(--spt-color-background-warm);
  color: var(--spt-color-accent-2);
  font-size: 0.75em;
}

@keyframes pulseYellow {
  0% {
    box-shadow: 0 0 0px 0px yellow;
  }
  10% {
    box-shadow: 0 0 10px 3px yellow;
  }
  100% {
    box-shadow: 0 0 0px 0px yellow;
  }
}

@keyframes pulseWhite {
  0% {
    box-shadow: 0 0 0px 0px wheat;
  }
  10% {
    box-shadow: 0 0 5px 3px wheat;
  }
  20% {
    box-shadow: 0 0 0px 0px wheat;
  }
  100% {
    box-shadow: 0 0 0px 0px wheat;
  }
}
.not-logged-in {
  background: var(--spt-color-background);
  color: var(--spt-color-accent-2);
  border-radius: 50px;
  // box-shadow: inset 1px 1px 3px -2px black, inset -1px -1px 3px -2px white;
  // animation: pulseYellow 30s ease 2, pulseWhite 60s ease 30s 10;
}

.right-arrow {
  background: transparent;
  transform: scale(2, 2) translate(37%, 0%);
  display: inline-block;
  color: inherit;
}

.login-modal {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  background-image: radial-gradient(var(--spt-color-accent-2), #ffffffd4);
  transition: 0.3s;
  opacity: 0;
  pointer-events: none;
}

body .bottom-bar .login-modal > div > div {
  width: fit-content;
  border-radius: 50px;
  padding: 3vh 0;
  * {
    max-width: 100%;
    min-width: auto;
  }
  > div {
    width: 100%;
  }
  div button {
    font-size: var(--spt-font-subheading-2) !important;
    background: var(--spt-color-accent-2-gradient) !important;
    color: var(--spt-color-background) !important;
    width: 100%;
    font-weight: 700;
    letter-spacing: 0.25em;
  }
}

.login-modal > div > button {
  border-radius: inherit;
  padding: 1vw;
  font-weight: 700;
  letter-spacing: 0.25em;
  text-transform: uppercase;
  border: none;
  box-shadow: 3px 3px 7px -5px white inset,
    -3px -3px 7px -5px rgba(0, 0, 0, 0.5) inset;
  cursor: pointer;
  font-variant: small-caps;
}

.login-bar-button {
  padding: 0 1em;
  margin-left: 1em;
  background: transparent;
  color: inherit;
  border: none;
  transition: 1s all ease;
  font: inherit;
  margin: 0 auto;
}

.login-modal > div > div:last-child {
  max-height: min-content;
  padding: 1em 0;
  margin: 1em auto;
}
.subscribe-cta-wrap {
  padding: 1em;
  margin: 0 auto;
}

@media screen and (max-width: 900px) {
  .bottom-bar {
    flex-direction: column;
  }
  .bottom-bar p {
    font-size: calc(1rem + 1vw);
  }
  .login-modal > div > div:last-child {
    display: none;
  }
}

@media screen and (max-width: 550px) {
  .bottom-bar p {
    font-size: calc(1rem + 0.5vw);
  }
}
