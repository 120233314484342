.slider-button-wrap {
  width: 100%;
  margin: 0 1em;
}

.slider-button-wrap > div {
  width: 100%;
  height: 100%;
  margin: auto;
}

.slider-button-wrap label {
  opacity: 1;
  width: 100%;
  height: 100%;
  min-height: 2em;
}
