.filtered-items-container {
    position: relative;
    box-shadow: 3px 3px 7px -4px inset black, -3px -3px 7px -4px inset #aaaaaa;
    border-radius: 6.25px;
    padding: 2em 1em;
    max-width: 100%;
}

.filtered-items-container:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #0000000d;
}

.filtered-items-title {
    font: var(--spt-font-subheading);
    margin: 0 auto;font-variant:small-caps;
    opacity: 0.8;
    background: transparent;
}

.filtered-items-sub-text {
    margin: 0;
    font-size: 0.7em;
    opacity: 0.7;
    background: transparent;
    font-weight: 700;
    font-variant:small-caps;
}

.no-items-text {
    margin: 1em;
    font-size: 0.7em;
}