.item {
  position: relative;
}
.category-children-amount {
  color: inherit;
  -webkit-text-fill-color: initial;
  box-shadow: none;
  background: transparent;
  font-family: Arial;
}

.protectedHidden-true,
[class*="protectedHidden-true"] {
  display: none !important;
}

.protectedVisible-true {
  textarea,
  [class*="sizable-textarea"],
  input,
  select {
    position: relative;
    display: block;
    background: inherit;
    color: inherit;
    border: none;
    /* text-overflow: ellipsis;
  white-space: nowrap; */
    resize: none;
    max-width: 100%;
    pointer-events: none;
    line-height: normal !important;
  }
}

[class*="master-parent-group"] [class*="dependencies-container"] li {
  textarea,
  [class*="sizable-textarea"],
  input,
  select {
    position: relative;
    display: block;
    background: inherit;
    color: inherit;
    border: none;
    /* text-overflow: ellipsis;
  white-space: nowrap; */
    resize: none;
    max-width: 100%;
    pointer-events: none;
    // line-height: normal !important;
    box-shadow: none;
    padding: 0;
    white-space: break-spaces;
  }
  [class*="sizable-textarea"][class*="name"] {
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

body ul[type="step"] .name {
  transform: translateY(-11%);
}

body ul[type="step"] [class*="featured-url-arrow-wrap"] {
  transform: translateY(-5%);
}

.name label {
  display: none;
}
/* li { */
/* width: 100%; */
/* flex-grow: 1;
  padding: 0.25em 0.5em;
} */

._id {
  min-width: 100%;
  margin: 0;
}
._id textarea,
._id [class*="sizable-textarea"] {
  background: inherit;
  color: inherit;
  border: none;
  pointer-events: none;
}

.identifier,
.createdAt,
.updatedAt,
.iframeCustomAttributes {
  flex-basis: 100%;
  min-width: 100%;
}

.iframeCustomAttributes,
.iframeCustomAttributes textarea,
.iframeCustomAttributes [class*="sizable-textarea"] {
  flex-grow: 1;
  min-height: max-content !important;
  max-height: none !important;
}

.identifier textarea,
.identifier [class*="sizable-textarea"],
.createdAt input,
.updatedAt input {
  pointer-events: none;
  opacity: 0.5;
}

.__v {
  display: none;
}

.subgroup-songs {
  display: flex;
  width: 100%;
}

.item label,
.item:before {
  opacity: 0.5;
  font-variant: all-small-caps;
  transition: 0.2s all ease;
  background: var(--spt-color-background);
  color: var(--spt-color-accent-dark);
  padding: 0.25vh 1vw;
  border-radius: 12.5px;
  margin-top: 1em;
  color: var(--spt-color-accent-2-dark);
  background: inherit;
  color: inherit;
  opacity: 0.75;
}

ul[type="goal"] {
  .item label,
  .item:before {
    // color: var(--spt-color-accent-2-dark);
  }
}

select {
  text-align-last: left;
  padding: 0.25em;
}

// .item:hover label,
// .item:hover:before {
//   opacity: 1;
// }

.item textarea,
.item [class*="sizable-textarea"],
.item input {
  width: 100%;
  /* height: 2em; */
  line-height: 2em;
  /* padding: 0 1em; */
  display: flex;
  /* word-break: break-all; */
  /* box-shadow: inset 0px -3px 3px -3px; */
  font-size: 1em;
  min-height: 1.25em;
  font: var(--spt-font-body-text);
  font-weight: 700;
  text-shadow:
    -1px -1px 1px rgba(0, 0, 0, 0.149),
    1px 1px 1px rgba(128, 128, 128, 0.09);
}

.item textarea,
.item [class*="sizable-textarea"],
.item input {
  overflow: hidden;
}

::placeholder {
  color: rgba(128, 128, 128, 0.592);
  font-family: inherit;
  text-transform: capitalize;
  font-style: italic;
}

.group .subgroup-tags li {
  width: 100%;
}

.subgroup-tags .group-title {
  text-transform: none;
  font: var(--font-body-text);
  font-size: 1.6rem;
  margin: 0;
}

.subgroup-tags textarea,
.subgroup-tags [class*="sizable-textarea"],
.subgroup .notes,
.subgroup .description {
  width: 100%;
}

.type {
  /* max-width: 10em; */
  min-width: fit-content;
  grid-area: left2;
}

/* Standard Grid Areas */
.group-title {
  grid-area: title;
}
.progressbar {
  grid-area: progressbar;
  width: 100%;
  transform: translate(0, -25%);
}

.progressbar > div {
  width: 100%;
  height: 1em;
  /* box-shadow: 2px 2px 4px -3px inset black, -2px -2px 4px -3px inset white, 1px 1px 4px -3px white, -1px -1px 4px -3px black; */
  border-radius: 50px;
  /* background: hsla(0, 0%, 0%, 0.05);*/
}

.progressbar > div span {
  transform: scale(2, 1.25) translateY(7%);
}

.progressbar label {
  display: none;
}
.name label {
  display: none;
}
body ul .name {
  grid-area: name;
  box-shadow: none;
  textarea,
  [class*="sizable-textarea"] {
    font-variant: small-caps;
    font-size: 0.9em;
    line-height: 0.9em !important;
    max-height: 1.75em;
    margin-top: 0.45em;
    overflow: hidden;
    margin-bottom: 1em;
    max-width: 96%;
  }
}
body ul[type="studyPlan"] > .name {
  padding-top: 0.05em;
}

body .method {
  grid-area: method; // font: var(--spt-font-body-text-12);
  input {
    font-variant: small-caps;
    text-transform: capitalize;
  }
}
.method label {
  display: none;
}

.method label:after {
  content: " (course, tutorial, project, etc.)";
  font-size: 75%;
}

.method input {
  font: inherit;
  font-weight: 700;
  overflow: visible;
  text-overflow: ellipsis;
  min-width: 10em;
}

.priority {
  grid-area: priority;
  display: flex;
  flex-direction: column;
  transform: translateY(-25%);
}

.priority label {
  display: none;
}

.priority:before {
  content: "Priority";
  position: relative;
  font-size: 1rem;
  transform: translateY(25%);
  text-align: center;
  line-height: 1rem;
  margin: 0 auto;
  padding: 0 0;
}

.msup {
  grid-area: msup;
}
.msup label {
  display: none;
}
.msup:before,
.goal-msup:before {
  content: "Goal or Step this Directly Works Towards";
}
.asup:before,
.goal-asup:before {
  content: "This also supports... ";
}
.asup label {
  display: none;
}
.asup {
  grid-area: asup;
}
.des {
  grid-area: des;
}
.des label {
  display: none;
}
body [class*="master-parent-group"] .des,
body [class*="master-parent-group"] .demonstratedskillsdesc,
body [class*="master-parent-group"] .itemnotes {
  textarea,
  [class*="sizable-textarea"] {
    background: var(--spt-color-background);
    color: var(--spt-color-background-contrast);
    max-width: calc(100% - 2em);
    padding: 0.75em 1em;
    box-shadow:
      1px 1px 3px -2px black inset,
      1px 1px 7px -4px rgba(0, 0, 0, 0.45) inset,
      4px 4px 5px -4px white;
    border-radius: 0 0 10px 10px;
    font-weight: 500;
    text-shadow: none;
    font-size: 14px;
  }
}

.des:before,
.goal-des:before {
  content: "Description:  ";
}
.url {
  grid-area: url;
}
.url label {
  display: none;
}
.url:before {
  content: "Link:  ";
}
.type {
  grid-area: type;
}
// .type label {
//   display: none;
// }
.type {
  grid-area: type;
}
.author {
  grid-area: author;
}
.author label {
  display: none;
}
.author:before {
  content: "author";
}
.platform {
  grid-area: platform;
}
.platform label {
  display: none;
}
.platform:before {
  content: "Platform:  ";
}
.start {
  grid-area: start;
}
.start label {
  display: none;
}
.start:before,
.goal-start:before {
  content: "Start:  ";
}
.acomp {
  grid-area: acomp;
}
.acomp label {
  display: none;
}
.acomp:before,
.goal-acomp:before {
  content: "Finish:  ";
}
.status:before,
.goal-status:before {
  content: "% Done(1-100):  ";
}
.status label {
  display: none;
}
.status {
  grid-area: status;
}
.lectureTime {
  grid-area: lectureTime;
}
.lectureTime label {
  display: none;
}
.lectureTime:before,
.goal-lectureTime:before {
  content: "Lecture Time:  ";
}
.labTime {
  grid-area: labTime;
}
.labTime label {
  display: none;
}
.labTime:before,
.goal-labTime:before {
  content: "Lab Time:  ";
}
.markcomplete {
  grid-area: markcomplete;
}
.markcomplete > label {
  display: none;
}
.markcomplete:before {
  content: "Complete";
  margin-bottom: 0.5em;
}
.markforreview {
  grid-area: markforreview;
}
.markforreview > label {
  display: none;
}
.markforreview:before {
  content: "Mark for Later Review";
  margin-bottom: 0.5em;
}
.tags {
  grid-area: tags;
}
div .markforreview,
div .markcomplete {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 1em;
  box-shadow:
    0 -9px 10px -12px white,
    0 9px 10px -12px black;
  min-height: 5em;
}
.demonstratedskillsdesc {
  grid-area: demonstratedskillsdesc;
}
.demonstratedskillsdesc label {
  display: none;
}
.demonstratedskillsdesc:before {
  content: "Demonstration of Skills  ";
}
.demonstratedskillurl {
  grid-area: demonstratedskillurl;
}
.demonstratedskillurl label {
  display: none;
}
.demonstratedskillurl:before {
  content: "Demonstration URL  ";
}
.itemnotes {
  grid-area: itemnotes;
}
.itemnotes label {
  display: none;
}
.itemnotes:before {
  content: "Notes  ";
}
.createdAt {
  grid-area: createdAt;
}
.createdAt label {
  display: none;
}
.createdAt {
  grid-area: createdAt;
}
.updatedAt label {
  display: none;
}
.updatedAt {
  grid-area: updatedAt;
}
.updatedAt {
  grid-area: updatedAt;
}
.identifier {
  grid-area: identifier;
}
._id {
  grid-area: _id;
}
h4._id {
  margin: 1em auto;
  background: inherit;
  color: inherit;
}
h4._id:before {
  content: "Database ID ";
  font: inherit;
  letter-spacing: inherit;
  background: inherit;
  color: inherit;
  -webkit-text-fill-color: inherit;
  font-feature-settings: "smcp", "c2sc";
  font-variant: all-small-caps;
  opacity: 0.5;
}
.id {
  grid-area: id;
}
.slug {
  grid-area: slug;
}
.masterLibraryID {
  grid-area: masterLibraryID;
}
li[class*="__v"] {
  display: none;
}
/* ********** */

/* Goal Section Grid Adjustments */
ul[type="goal"] > [data-container-type="collapsibleElm"] > {
  .priority,
  .method,
  .labTime,
  .lectureTime,
  .platform,
  .author,
  .progressbar {
    display: none;
  }
}

/* ********** */

@keyframes pulseYellow {
  0% {
    box-shadow: 0 0 0px 0px yellow;
  }
  10% {
    box-shadow: 0 0 10px 3px yellow;
  }
  100% {
    box-shadow: 0 0 0px 0px yellow;
  }
}

@keyframes pulseAccent {
  0% {
    box-shadow: 0 0 0px 0px var(--spt-color-accent);
  }
  10% {
    box-shadow: 0 0 10px 3px var(--spt-color-accent);
  }
  100% {
    box-shadow: 0 0 0px 0px var(--spt-color-accent);
  }
}

ul[class*="edited-list"]
  > [data-container-type="collapsibleElm"]
  > .edited-field {
  animation: pulseYellow 2s ease infinite;
  padding: 1em !important;
  background: yellow;
  color: black;
  margin: 1em auto 0;
}

.edited-field:after {
  content: "Edited";
  animation: pulseYellow 2s ease infinite;
  padding: 0.5em !important;
  background: yellow;
  color: black;
  /* margin: 1em auto 0; */
  position: absolute;
  top: 0;
  left: 0;
  font-size: 7px;
  transform: translate(50%, 50%);
  border-radius: 50px;
}

li[class*="name"].edited-field:after {
  margin-left: 92px;
  transform: translate(50%, -17%);
  border-radius: 50px;
}

ul[class*="edited-list"] li[class*="name"].edited-field:after {
  transform: translate(50%, 0%);
  border-radius: 50px;
}

textarea,
[class*="sizable-textarea"] {
  max-width: 100%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@media screen and (max-width: 650px) {
  .item textarea,
  .item [class*="sizable-textarea"],
  .item input {
    text-shadow: none;
  }

  body ul .name {
    textarea,
    [class*="sizable-textarea"] {
      font-size: calc(0.6em + 0.5vmin);
    }
    [class*="sizable-textarea"] {
      margin-left: 3em;
      padding: 0 1.25em 0 0 !important;
    }
  }

  select {
    text-align-last: center;
  }
}
@media screen and (max-width: 1024px) {
  body ul[type="step"] .name {
    transform: translateY(-7%);
  }

  body ul[type="step"] [class*="featured-url-arrow-wrap"] {
    transform: translateY(-4%);
  }

  body [class*="featured-url-arrow-wrap"] {
    width: 5.5em !important;
    border-radius: 0 0 39px;
  }
}

@media screen and (max-width: 650px) {
  body ul[type="step"] .name,
  body ul[type="step"] [class*="featured-url-arrow-wrap"] {
    transform: translateY(-9%);
  }
}
