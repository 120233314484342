.button {
  margin: 0 0.5em;
  box-shadow:
    3px 3px 7px -5px white inset,
    -3px -3px 7px -5px rgba(0, 0, 0, 0.5) inset;
  border: none;
  background: inherit;
  color: inherit;
  border-radius: 50px;
  padding: 0.5em 1em;
  margin-top: 1em;
  font-weight: 700;
  cursor: pointer;
  transition: 0.3s all ease;
  font-variant: small-caps;
}

.button span {
  background: transparent;
  color: inherit;
  text-align: inherit;
  width: 100%;
  pointer-events: none;
}

/* Color Control */
.button.primary {
  background-color: var(--spt-color-accent);
  color: var(--spt-color-background);
}

.button.primary.selected {
  box-shadow:
    inset 1px 1px 1px rgb(255 255 255 / 25%),
    inset 1px -1px 1px rgb(0 0 0 / 15%),
    1px 1px 5px -3px black,
    0 0 10px white,
    inset 0 0 10px white;
}

.button.secondary {
  background: var(--spt-color-background);
  color: var(--spt-color-background-contrast-light);
}

.button.secondary.selected {
  box-shadow:
    inset 1px 1px 1px rgb(255 255 255 / 25%),
    inset 1px -1px 1px rgb(0 0 0 / 15%),
    1px 1px 5px -3px black,
    0 0 10px white,
    inset 0 0 10px white;
}

.button.basic {
  background: var(--spt-color-background);
  color: var(--spt-color-background-contrast);
}

/* Font Control */
.button.heading {
  letter-spacing: var(--spt-spacing-heading);
}
.button.heading-2 {
  font: var(--spt--font-heading-2);
  letter-spacing: var(--spt-spacing-heading);
}
.button.subheading {
  font: var(-font-subheading);
  letter-spacing: var(--spt-spacing-subheading);
}
.button.body {
  font: var(--spt-font-body-text);
  font-weight: 700;
}
.button.small {
  font: var(--spt-font-body-text);
  font-size: 0.75em;
  /* font-size: calc(0.5em + 0.2vw); */
  font-variant: all-small-caps;
  letter-spacing: 0.125em;
  font-weight: 700;
}

.button.medium {
  font: var(--spt-font-body-text);
  font-size: 1em;
  /* font-size: calc(0.5em + 0.2vw); */
  font-variant: all-small-caps;
  letter-spacing: 0.125em;
  font-weight: 700;
}

.button.large {
  font: var(--spt-font-body-text);
  font-size: 1.5em;
  font-size: calc(1em + 0.5vw);
  font-weight: 700;
}

/* Eliminates hover effects on mobile */
@media (hover: hover) {
  .button:hover {
    text-shadow: 0 0 1px;
    transform: scale(1.1);
    transition: 0.1s all ease;
  }

  .button.secondary:hover {
    color: var(--spt-color-background-contrast);
  }
}
