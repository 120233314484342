.noticeOne-container {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  font-size: 1.5em;
  background: var(--spt-color-background);
  width: 100%;
  padding: 0 0 1em;
}

.title-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 5em;
}
.anchor-page-bookmark {
  position: relative;
  top: calc(0px - var(--spt-navbar-height));
}
.about-text-container {
  margin: 0 auto 1em;
  box-shadow: 0 15px 30px -15px var(--spt-color-accent);
}

.image-wrap {
  position: relative;
}

.image-wrap:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    var(--spt-color-background) 6.25%,
    transparent 25%
  );
}

.image {
  display: inline-block;
  max-width: 100%;
  max-height: 100%;
  min-height: 100%;
}

.image-one {
  /* margin-bottom: -5em; */
  max-width: 100%;
  min-height: 100%;
  min-width: 100%;
}

.sub-title-background {
  position: absolute;
  background: linear-gradient(0deg, black 25%, #000000a6 70%, transparent);
  max-width: -moz-fit-content;
  padding: 1em 2em;
  width: 100%;
  background: linear-gradient(
    0deg,
    var(--spt-color-background) 12.5%,
    transparent
  );
  min-height: 25%;
  bottom: 0;
  display: flex;
}

.sub-title {
  position: relative;
  max-width: 800px;
  margin: auto auto 3.125%;
}

.text-pic-container {
  position: relative;
  display: block;
}

.text-pic-container-two > * {
  flex-basis: 50%;
}

.text-pic-container-two .text {
  /* text-align: left; */
  position: relative;
}

.text-pic-container-two .image-wrap {
  position: relative;
  max-width: 50%;
  margin-right: -5em;
  display: inline;
  float: left;
}

.text-pic-container-two .image-wrap:after {
  background: linear-gradient(
    270deg,
    var(--spt-color-background) 3.125%,
    transparent 25%
  );
  box-shadow: 0 0 58px 31px var(--spt-color-background) inset;
}

/* Image & Text Three */
.text-pic-container-three .text {
  z-index: 1;
}

.text-pic-container-three .image-wrap {
  position: relative;
  max-width: 50%;
  margin-left: -5em;
}

.text-pic-container-three .image-wrap:after {
  background: linear-gradient(
    90deg,
    var(--spt-color-background) 6.25%,
    transparent 25%
  );
  box-shadow: 0 0 58px 31px var(--spt-color-background) inset;
}

/* Image & Text Four */
.text-pic-container-four {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text-pic-container-four .text {
  z-index: 1;
}

.text-pic-container-four .image-wrap {
  position: relative;
  max-width: 100%;
  margin-top: -5em;
}

.text-pic-container-four .image-wrap:after {
  background: linear-gradient(
    180deg,
    var(--spt-color-background) 6.25%,
    transparent 25%
  );
  box-shadow: 0 0 58px 31px var(--spt-color-background) inset;
}

p {
  max-width: 800px;
  margin: 1em auto;
}

.text {
  z-index: 1;
  max-height: 100%;
  height: fit-content;
  margin: auto;
}

.text-one {
  margin-top: 0;
}

@media screen and (max-width: 900px) {
  .bottom-bar {
    flex-direction: column;
  }
  .bottom-bar p {
    font-size: calc(6px + 1vw);
  }
  .login-modal > div > div:last-child {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .text-pic-container {
    display: flex;
    flex-direction: column;
  }

  .text-pic-container-two .image-wrap {
    display: flex;
    margin: auto auto -2em;
    width: 100%;
    max-width: 100%;
  }
  .text-pic-container-two .image-wrap:after {
    box-shadow: 0 0 58px 31px var(--spt-color-background) inset;
    background: linear-gradient(
      360deg,
      var(--spt-color-background) 3.125%,
      transparent 25%
    );
  }
}
