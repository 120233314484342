.collapsible-elm {
  overflow: hidden;
}

.elm-closed.show-bottom-gradient:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  box-shadow: inset 0px -114px 57px -57px var(--spt-color-background-warm);
}
