.not-found-container {
  position: relative;

  max-width: 100%;
}

/* MODAL */
.modal {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  min-width: 100%;
  max-width: 100%;
  min-height: 100%;
  max-height: 100%;
  background-image: radial-gradient(var(--spt-color-accent), #000000d4);
  transition: 0.3s;
  z-index: 10000;
}

.modal .inner-container {
  width: fit-content;
  border-radius: 50px;
  padding: calc(1em - 1vw);
  flex-direction: column;
  justify-content: space-between;
  width: 80%;
  max-width: 800px;
  max-height: 100%;
  overflow: auto;
  background: var(--spt-color-background);
  padding: 2em;
}

.modal button {
  position: relative;
  width: 100%;
  max-width: 400px;
  border-radius: inherit;
  padding: 1vh 1vw;
  box-shadow: 3px 3px 7px -5px white inset,
    -3px -3px 7px -5px rgba(0, 0, 0, 0.5) inset;
  cursor: pointer;
  text-transform: uppercase;
  font: inherit;
  font-weight: 700;
  font-size: calc(1em + 1vmin);
  letter-spacing: 0.25em;
  color: inherit;
  border: none;
  transition: 0.5s all ease;
  background-color: var(--spt-color-background);
  background-image: var(--spt-color-accent-gradient);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.modal button:hover {
  box-shadow: 3px 3px 7px -5px white inset,
    -3px -3px 7px -5px rgba(0, 0, 0, 0.5) inset;
  color: var(--spt-color-background);
  background: var(--spt-color-accent-gradient);
  transition: 0.5s all ease;
  transform: none;
  -webkit-text-fill-color: inherit;
}

@media screen and (max-width: 900px) {
}

@media screen and (max-width: 1000px) {
}
