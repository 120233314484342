.login-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 3em;
}
.login-container h3 {
  padding: 0;
  margin-bottom: 0;
  font: var(--spt-font-subheading);
}

.login-container .login-question button {
  font-variant: small-caps;
  letter-spacing: var(--spt-spacing-subheading);
  font-size: calc(0.8em + 0.05vw);
  font-weight: 700;
}

.login-container .form-container .login-container button {
  background-color: var(--spt-color-background);
  background-image: var(--spt-color-accent-gradient);
  color: var(--spt-color-background);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.login-container .form-container .login-container button:hover {
  background: var(--spt-color-accent-gradient);
  color: var(--spt-color-background);
  -webkit-background-clip: inherit;
  background-clip: text;
  -webkit-text-fill-color: inherit;
}

.form {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  justify-content: center;
}

.form > * {
  flex-basis: 19.33%;
  flex-grow: 1;
}
.form input[type="text"],
.form input[type="password"],
.form input[type="email"],
.form button {
  font-size: 1rem;
  border-radius: 50px;
  padding: 0px 2em;
  margin: 0;
}

.form label {
  margin: 0;
  line-height: 1em;
  padding: 0;
  justify-content: inherit;
  display: none;
}
.form > div > div {
  padding: 0;
  margin: 0 auto;
  height: 2em;
}

.form button {
  height: 2em;
  letter-spacing: var(--spt-spacing-subheading);
  font-size: calc(0.5em + 0.2vw);
  font-weight: 700;
}

.login-form-wrap {
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  margin: 1em auto 0;
}
.form-submit-button-wrap {
  display: flex;
  align-items: flex-end;
}

.form-submit-button-wrap button {
  margin: 0 auto 10px;
}
.login-in-link {
  margin-left: 1em;
}

.login-question {
  margin-top: 1em;
}
.login-question button {
  margin-left: 1em;
  width: auto;
}

.form-input-error {
  position: relative;
  max-width: fit-content;
  border: 2px solid #28709452;
  box-shadow:
    3px 3px 10px -7px inset black,
    -3px -3px 10px -7px inset white;
  border-radius: 10px;
  margin: 0 auto;
  margin-top: 1em !important;
  padding: 1em 4em 0em;
  font-size: 1.25rem;
  font-weight: 700;
  background: var(--spt-color-accent-2);
  color: var(--spt-color-background);
  margin-top: 1em;
  min-width: 100%;
  p {
    color: inherit;
    background: transparent;
    padding: 2em;
    margin-top: 1e;
  }
}

.form-input-error .form-input-error-close-button {
  position: absolute;
  top: 0.25em;
  right: 0.25em;
  font-weight: 700;
  margin: 0 auto;
  width: 1em;
  padding: 0;
  background: none;
  color: inherit;
  border: none;
  box-shadow: none;
  transition: 0.2s all ease;
}

.form-input-error-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: white;
  color: black;
}
.horizontal-display .form-input-error .form-input-error-close-button {
  position: absolute;
  left: 0;
  font-size: 1.8rem;
  font-weight: 700;
}

.form-input-error .form-input-error-close-button:hover {
  transform: scale(1.25);
}
.form-input-error p {
  /* font-size: 2rem; */
}

.login-container input::placeholder {
  color: hsl(0deg 0% 0% / 50%);
  font-weight: 100;
}

/* HORIZONTAL DISPLAY */
.login-container.horizontal-display {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  padding: 0;
  max-width: max-content;
  align-items: center;
}

.login-container.horizontal-display .login-question,
.login-container.horizontal-display .button-container,
.login-container.horizontal-display .login-form-wrap,
.login-container.horizontal-display .form {
  display: flex;
  flex-direction: row;
  height: 100%;
  padding: 0;
  margin: 0;
  font: var(--spt-font-body-text);
  max-width: fit-content;
  font-size: 1rem;
  flex-wrap: nowrap;
}

.login-container.horizontal-display .login-question {
  margin: 0 7%;
}

.login-container.horizontal-display .form {
  margin: 0 auto;
  max-height: 100%;
}
.login-container.horizontal-display label {
  display: none;
}

.login-container.horizontal-display .form input,
.login-container.horizontal-display .form div {
  align-items: normal;
  font-size: 1rem;
  padding: 0;
  margin: 0 auto;
  height: 30px;
}
.login-container.horizontal-display .form input {
  margin: 0.25em 0;
  box-shadow:
    inset 2px 2px 3px -2px black,
    inset -2px -2px 3px -2px white;
  border: 1px inset #cecece;
}

.horizontal-display .form-submit-button-wrap button,
.horizontal-display .login-question button {
  width: 13em;
  font-size: 1rem;
  margin: 0;
  padding: 0;
  min-width: 8em;
  min-height: 100%;
  margin: 0;
  padding: 0;
  margin-top: auto;
  height: 30px;
}
.horizontal-display .login-question {
  text-align: right;
}

.horizontal-display .login-question button {
  background: var(--spt-color-accent-gradient);
  /* border-radius: 15px; */
  color: var(--spt-color-background);
  text-shadow: 0 0 3px rgb(245, 222, 179);
  background: linear-gradient(rgb(255 135 0) 37%, rgba(0, 0, 0, 1) 100%);
  margin-left: 1em;
  margin-top: auto;
  height: 30px;
}

.horizontal-display ::placeholder {
  display: block !important;
  opacity: 1;
  color: #7f7f7f;
  text-align: center;
}

.horizontal-display .form-input-error {
  position: absolute;
  top: calc(100% + 1em);
  max-width: 100%;
  min-width: 0;
  padding: 0.5em 3em 1em !important;
  background: var(--spt-color-background-gradient);
  color: var(--spt-color-background);
  left: 50%;
  transform: translateX(-50%);
  border-radius: 0 0 25px 25px;
  width: max-content;
}

.form > *,
.input-container {
  flex-grow: 1;
  flex-basis: min-content;
}

.form button {
  margin: 0 1em;
  min-height: 100%;
}

.change-password-iframe {
  border: none;
  border-radius: 30px;
  box-shadow:
    -2px -2px 3px -2px black,
    2px 2px 3px -2px white;
}

@media only screen and (max-width: 500px) {
  .form *,
  form .input-container {
    min-width: 100%;
    margin: 0.5em auto;
  }
}

@media only screen and (max-width: 768px) {
  .form {
    max-width: 100%;
    overflow: hidden;
  }
}
