.input-container {
  flex-grow: 1;
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: left;
  min-height: 2em;
  /* flex-direction: column; */
  padding: 1em;
  /* flex-basis: calc(25% - 4em); */
  height: 100%;
  flex-basis: 50%;
  max-width: 100%;
  flex-wrap: wrap;
}
.input-container {
  /* flex-grow: 1; */
  margin-top: 1rem;
  margin-bottom: 1rem;
  display: flex;
  justify-content: left;
  /* min-height: 2em; */
  /* flex-direction: column; */
  padding: 1em;
  flex-basis: fit-content;
  height: 100%;
  max-width: 100%;
  flex-wrap: wrap;
  width: min-content;
  width: min-content;
  min-width: min-content;
}

.input-container > label {
  flex-basis: 100%;
  font-variant: small-caps;
  margin: 0 auto 0.5em;
  font-size: 1.6rem;
  letter-spacing: var(--spt-spacing-heading);
}
.input-container .input-wrap {
  display: flex;
  width: 100%;
  min-height: 100%;
  text-align: left;
  max-width: 16em;
  max-height: 3em;
  min-width: fit-content;
  width: fit-content;
  overflow: hidden;
  font-size: 1.4rem;
}

.required-input-error-text {
  width: 100%;
}

/* .display-row {
  flex-basis: 33.33%;
} */

.input-container input[type="text"],
.input-container input[type="email"],
.input-container input[type="password"] {
  flex-basis: 100%;
  flex-grow: 1;
  padding: 0.5em;
  font-size: 18px;
  font-weight: 700;
  border: none;
  margin-bottom: 0.25em;
}

.input-container select,
.input-container option {
  padding: 0.5em;
  text-align: center;
  color: var(--spt-color-accent);
  background: var(--spt-color-background-gradient);
}

.input-container option {
  text-align: center;
}

.required-input-error {
  border: 14px inset var(--spt-color-accent) !important;
  color: var(--spt-color-accent) !important;
}
.required-input-error-text {
  color: var(--spt-color-accent) !important;
  font-size: 1.5em;
  font-size: calc(1em + 0.5vw);
}

.required-input {
  border: 5px solid var(--spt-color-accent) !important;
}

.input-container textarea {
  min-width: 100%;
  max-height: 100%;
  min-height: 3em;
  resize: vertical;
}

.input-container input[type="url"] {
  height: 3em;
  width: 100%;
}

::placeholder {
  opacity: 0.5;
  font-weight: 100;
  font-style: italic;
}
/* *** Checkbox Styles *** */
.input-container input[type="checkbox"] + label {
  flex-grow: 1;
  max-width: calc(100% - 2em);
  font-size: inherit;
  font-weight: 700;
  border: none;
  padding: 0.75em 0.5em 0.75em 0.5em;
  margin: 0 auto 0 -0.4em;
  min-width: fit-content;
  z-index: 2;
}
.input-container input[type="checkbox"] {
  position: relative;
  display: flex;
  -webkit-appearance: none;
  background-color: #fafafa;
  border: 1px solid #cacece;
  box-shadow:
    2px 2px 5px -1px rgb(255 255 255 / 50%),
    inset 3px 3px 5px -1px hsl(0deg 0% 0% / 50%);
  padding: 9px;
  border-radius: 3px;
  display: inline-block;
  position: relative;
}
.input-container input[type="checkbox"]:active,
.input-container input[type="checkbox"]:checked:active {
  box-shadow:
    0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.input-container input[type="checkbox"]:checked:after {
  content: "\2714";
  font-size: 28px;
  position: absolute;
  font-weight: 900;
  top: 50%;
  left: 0px;
  max-width: 110%;
  min-width: 110%;
  min-height: 110%;
  color: var(--spt-color-accent);
  background: var(--spt-color-background);
  box-shadow: none;
  transform: scale(1.5) translate(12%, -32%);
  text-shadow: 0 0 5px var(--spt-color-ackground-warm);
}

.input-container input[type="checkbox"]:after {
  /* content: "\2714"; */
  content: "";
  font-size: 28px;
  position: absolute;
  font-weight: 900;
  top: 50%;
  left: 0px;
  max-width: 100%;
  min-width: 100%;
  min-height: 100%;
  color: var(--spt-color-background);
  background: var(--spt-color-accent-gradient);
  text-shadow:
    0 0 1px rgb(0, 0, 0),
    0 0 3px rgb(255, 255, 255);
  transform: translateY(-50%);
  /* color: black; */
  box-shadow:
    inset -2px -2px 7px -5px black,
    inset 3px 3px 7px -5px white;
  background: rgba(0, 0, 0, 0.206);
}
/****************/

/* *** App-Specific Fields *** */
.name,
.input-company {
  flex-basis: 100%;
  flex-grow: 1;
  padding: 0.5em;
  font-size: 18px;
  font-weight: 700;
  border: none;
  text-align: center;
}
.input-container.name input,
.input-container.input-company input {
  text-align: center;
  font-size: 1em;
}

.input-container.input-company select {
  text-align: center;
  font-size: 2em;
  width: 100%;
}

.input-container.input-color,
.input-container.input-precision {
  justify-content: center;
}
.input-container.input-color .input-wrap,
.input-container.input-precision .input-wrap {
  flex-basis: 33.3%;
}

.input-container.productURL,
.input-container.photoURL {
  flex-basis: 100%;
}

.input-favorite,
.input-container.input-rating,
.input-container.input-status,
.input-container.input-oversampling {
  width: 11em;
  flex-shrink: 1;
  max-width: 17em;
  justify-content: space-evenly;
  text-align: center;
}
.input-container.input-status select,
.input-container.input-status text {
  width: 100%;
}

.input-container.input-status input {
  text-align: center;
}

.input-favorite > .input-wrap {
  max-width: 50%;
  margin: 0 auto;
}

.input-rating .input-wrap {
  width: fit-content;
  margin: 0 auto;
}
.input-container.notes {
  flex-basis: 100%;
  min-height: 100%;
  flex-grow: 1;
  text-align: center;
}

/* .input-container._id,
.input-container.identifier,
.input-container.masterlibraryid {
  display: none;
} */

.input-container._id,
.input-container.identifier,
.input-container.masterLibraryID,
.input-container.id {
  opacity: 0.5;
  transition: 0.3s all ease;
  transition-delay: 0.5s;
  pointer-events: none;
  flex-basis: 25%;
}

/* Special Styling for Functions */

.input-container.input-container.input-functions .input-wrap {
  position: relative;
  min-height: -moz-fit-content;
  min-height: fit-content;
  flex-wrap: wrap;
  padding: 0.75em 2em;
  max-height: none;
  width: 100%;
  min-width: min-content;
  max-width: 100%;
  box-shadow:
    inset 2px 2px 3px -2px black,
    inset -2px -2px 3px -2px white;
  overflow: visible;
  border-radius: 10px;
  flex-basis: 18%;
}

.input-container.input-container.input-functions {
  flex-basis: 100%;
  justify-content: center;

  display: grid;
  grid-template-columns: repeat(7, 1fr);
  /* grid-auto-flow: column; */
  grid-auto-flow: column;
  grid-gap: 10px;
}

.input-container.input-functions input[type="checkbox"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(24 24 24 / 50%);
  /* background: linear-gradient(
    0deg,
    rgb(0 0 0 / 30%) 0%,
    rgb(0 0 0 / 19%) 13%,
    hsl(0deg 0% 100% / 0%) 30%,
    rgb(255 255 255 / 58%) 76%,
    #fafafa8f 99%,
    #000000b3 100%
  ); */
  border: 2px outset #4d4d4d;
  left: 50%;
  top: 50%;
  margin: 10px;
  transform: translate(calc(-50% - 10px), calc(-50% - 10px));
  border-radius: inherit;
  box-shadow:
    inset 5px 5px 5px -5px rgb(255 255 255 / 50%),
    inset -5px -5px 5px -4px hsl(0deg 0% 0% / 50%),
    3px 3px 7px -4px hsl(0deg 0% 0% / 50%);
}

.input-container.input-functions input[type="checkbox"]:checked,
body .input-container.input-functions input[type="checkbox"]:hover:after {
  background: #e9ecee00;
  box-shadow:
    0 1px 2px 0px rgb(0 0 0 / 5%),
    inset 0px -15px 10px -12px rgb(0 0 0 / 5%),
    inset 0px 15px 10px -12px rgb(255 255 255 / 10%);
  color: #99a1a7;
  border: 4px inset #4d4d4d;
  left: 50%;
  top: 50%;
  margin: 10px;
  transform: translate(calc(-50% - 10px), calc(-50% - 10px));
  border-radius: inherit;
  border: 2px solid wheat;
}

body .input-container.input-functions input[type="checkbox"]:checked:after,
body .input-container.input-functions input[type="checkbox"]:hover:after {
  content: "";
  font-size: 28px;
  position: absolute;
  font-weight: 900;
  top: 50%;
  left: 0px;
  max-width: 101%;
  min-width: 100%;
  min-height: 100%;
  background: none;
  box-shadow:
    inset 0 0 100px -67px white,
    0 0 21px -3px #f2f27a80;
  transform: translateY(-50%);
  margin: auto;
}
body .input-container.input-functions input[type="checkbox"]:hover:after {
  border: 1px outset;
}
body .input-container.input-functions input[type="checkbox"]:hover {
  background-color: #00000028;
}

.input-container.input-functions input[type="checkbox"] + label {
  padding: 0;
  /* font-size: 1.25em; */
  z-index: 10;
  pointer-events: none;
  color: wheat;
  opacity: 0.8;
}

.input-container.input-functions label {
  grid-row: 1;
  grid-column: 1 / span 7;
}

.input-container.input-functions input {
  grid-row: 2;
  grid-column: 1 / span 7;
}

.input-container.input-functions input[type="checkbox"]:checked + label,
.input-container.input-functions input[type="checkbox"]:hover + label {
  color: inherit;
  opacity: 1;
}

.input-container.input-functions .input-wrap:before {
  width: 100%;
  flex-basis: 100%;
  font-size: 0.9em;
  opacity: 0.5;
  letter-spacing: 0.25em;
  margin-bottom: 0.5em;
  font-weight: 700;
}

[data-group^="SPACER"],
[data-group^="SPACER"] * {
  background: transparent;
  color: transparent;
  box-shadow: none;
  text-align: center;
  border: none !important;
  border-radius: 0px !important;
  width: 100%;
  min-width: 100% !important;
  pointer-events: none !important;
  box-shadow: none !important;
  padding: 0 !important;
  align-items: center;
}

[data-group^="SPACER"] {
  border: 1px inset !important;
  box-shadow: inset 0 0 175px -50px wheat !important;
  text-shadow: 0 0 3px rgb(245, 222, 179);
  padding: 0.25em !important;
}

[data-group^="SPACER"] input:after {
  background: transparent;
  color: transparent;
  box-shadow: none;
  text-align: center;
  border: 1px inset gray !important;
  border-radius: inherit;
  width: 100%;
  min-width: 100% !important;
  pointer-events: none !important;
  box-shadow:
    inset -2px -2px 7px -5px white,
    inset 3px 3px 7px -5px black !important;
}
[data-group^="SPACER"] label {
  font-size: 1.25em !important;
}

[data-group*="Category"] {
  background: var(--spt-color-background-gradient);
  color: var(--spt-color-accent);
  grid-column: 1 / span 2;
  min-width: 100% !important;
}

[data-group*="Dynamics"] {
  background: rgb(2, 218, 218);
  color: #1e1e1e;
  grid-column: 4;
}

[data-group*="FrequencyControl"] {
  background: rgb(218, 182, 2);
  color: #1e1e1e;
  grid-column: 3;
}

[data-group*="Saturation"] {
  background: rgb(218, 2, 2);
  color: #fefefe;
  grid-column: 3;
}

[data-group*="TimeandSpace"] {
  background: rgb(102, 102, 102);
  color: #fefefe;
  grid-column: 5;
}
[data-group*="Modulation"] {
  background: rgb(52, 218, 2);
  color: #1e1e1e;
  grid-column: 6;
}

[data-group*="Simulation"] {
  background: rgb(168, 2, 218);
  color: #fefefe;
  grid-column: 7;
}
[data-group*="CombinationTools"] {
  background: rgb(218, 142, 2);
  color: #222222;
  grid-column: 7;
}

[data-group*="Multi-FunctionShaper"] {
  background: rgb(2, 150, 218);
  color: #fefefe;
  grid-column: 5;
}

[data-group*="Analyzers"] {
  background: rgb(218, 2, 2);
  color: #fefefe;
  grid-column: 6;
}

.input-container .MIDI {
  background: rgb(218, 2, 142);
  color: #fefefe;
}

.MIDI:before {
  content: "MIDI";
}

.input-container .UserAdded {
  background: rgb(33, 33, 33);
  color: #fefefe;
}

.UserAdded:before {
  content: "User Added";
}
