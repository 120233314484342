.hours-wrap {
  max-height: none;
  height: auto;
  max-width: 100%;
  box-shadow: none;
  border: none;
  overflow: visible;
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
}

.hours-wrap * {
  margin: 1% 0;
}

.hours-wrap .inner-block {
  max-height: none;
  text-align: center;
  transition: 0.3s all ease;
  transition-timing-function: cubic-bezier(0.81, 1.44, 0, 0.64);
  -webkit-font-smoothing: subpixel-antialiased;
  transform: scale(1) translate3d(0, 0, 0) translateZ(0);
  backface-visibility: hidden;
  // font-size: 0.7em;
  padding-bottom: 0;
  opacity: 0.7;
  flex-basis: 31.33%;
}

.hours-wrap:before {
  content: "Stats";
  text-align: center;
  display: block;
  width: 100%;
  border-bottom: 1px solid #00000017;
  padding-bottom: 0.5em;
  text-transform: uppercase;
  letter-spacing: 0.5em;
  font-size: 75%;
  opacity: 0.7;
}

.hours-wrap .inner-block.reviewhrsadjusted,
.hours-wrap .inner-block.completehrstotal,
.hours-wrap .inner-block.collegecredithours {
  flex-direction: column-reverse;
  flex-basis: 31.33%;
  font-size: 1.25em;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  opacity: 0.8;
}

.hours-wrap .inner-block:hover {
  transform: scale(1.15) translate3d(0, 0, 0) translateZ(0);
  backface-visibility: hidden;
  opacity: 1;
}

.hours-wrap .inner-block.reviewhrsadjusted:hover,
.hours-wrap .inner-block.completehrstotal:hover {
  transform: scale(1.15) translate3d(0, 0, 0) translateZ(0);
  backface-visibility: hidden;
  opacity: 1;
}

.hours-wrap .inner-block:before {
  display: block;
  font-size: 0.6em;
}

.hours-wrap .labhrstotal:before {
  content: "Total Lab Hours";
}

.hours-wrap .lecturehrstotal:before {
  content: "Total Lecture Hours";
}

.hours-wrap .completehrstotal:before {
  content: "Total Hours Completed";
}

.hours-wrap .reviewhrstotal:before {
  content: "Full Review Hours";
}

.hours-wrap .reviewhrsadjusted:before {
  content: "Review Hours Needed";
}

.hours-wrap .totalhours {
  flex-basis: 31.33%;
  flex-grow: 1;
  // background: var(--spt-color-accent);
  background: var(--spt-color-accent-2);
  color: var(--spt-color-background);
  border-radius: 50px;
  padding: 0.5em;
}

.hours-wrap .totalhours:before {
  content: "Total Program Length: ";
}

.hours-wrap .collegecredithours:before {
  content: "Simulated College Credits";
  margin-right: 1em;
}

@media screen and (max-width: 900px) {
  // .hours-wrap .totalhours {
  //   padding: 1em;
  // }
}

@media screen and (max-width: 550px) {
  .hours-wrap {
    .totalhours {
      flex-basis: 100%;
      padding: 1em;
    }
    .labhrstotal,
    .lecturehrstotal {
      flex-basis: 50%;
    }
  }
}
